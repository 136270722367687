
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import ImageIcon from '@/components/icons/Image.vue'
import UserFollowBox from './UserFollowBox.vue'
import Separator from '@/components/Separator.vue'
import Progress from './Progress.vue'
import Button from '@/components/auth/Button.vue'
import Activity from './Activity.vue'
import LinkButton from "@/components/mobile/LinkButton.vue";
import Pencil from "@/components/icons/Pencil.vue";
import InAppLink from "@/components/utils/InAppLink.vue";
import ProfileImageLazy from "@/components/users/ProfileImageLazy.vue";

@Options({
     computed: {
       ...mapGetters({ user: 'auth/user' }),
       premiumCubeImage() {
         return(
             this.user.is_premium && this.user.premium_settings &&
             this.user.premium_settings.cube_profile_image
         )
       }
     },
     components: {
       ProfileImageLazy,
       InAppLink,
       Pencil,
       LinkButton,
          ImageIcon,
          UserFollowBox,
          Separator,
          Progress,
          Button,
          Activity
     },
})
export default class MePageView extends Vue {}
