
import { Options, Vue } from 'vue-class-component';
import ProfileImageLazy from "@/components/users/ProfileImageLazy.vue";

@Options({
  components: {ProfileImageLazy},
  props: {
    user: String || null,
    is_anonymous: Boolean || null,
    show_is_online: Boolean,
  },
  computed: {
    premiumCubeImage() {
      return(
          !this.is_anonymous && this.user && this.user.is_premium && this.user.premium_settings &&
          this.user.premium_settings.cube_profile_image
      )
    }
  }
})
export default class extends Vue {
  user!: object;
  is_anonymous!: boolean;
  show_is_online!: boolean;
}
