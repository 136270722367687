
import { Options, Vue } from 'vue-class-component'
import Back from '@/components/icons/Back.vue'
import InAppLink from '@/components/utils/InAppLink.vue'

@Options({
     components: {
          InAppLink,
          Back,
     },
     props: {
          title: String || null,
          to: String||null,
     },
})

export default class MainLoader extends Vue {
     title !: string|null;
     to !: string|null;
}
