
import {defineComponent} from 'vue'
import Sheet from "@/components/utils/Sheet.vue";
import Heart from "@/components/icons/Heart.vue";
import Button from "@/components/auth/Button.vue";

export default defineComponent({
  name: "AnswerSheet",
  components: {Button, Heart, Sheet},
  props: {
    showSheet: Boolean,
  }
})
