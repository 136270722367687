
import { Options, Vue } from 'vue-class-component'
import DevEditIcon from '@/components/icons/DevEdit.vue'
import ItemWrap from '@/components/home/topItems/ItemWrap.vue'

@Options({
     components: {
          DevEditIcon,
          ItemWrap
     },
})
export default class UpdatePostComponent extends Vue {}
