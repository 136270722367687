
import {Options, Vue} from "vue-class-component";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import Play from "@/components/icons/Play.vue";
import Pause from "@/components/icons/Pause.vue";
import Button from "@/components/auth/Button.vue";
import {mapGetters} from "vuex";

type ArtistType = {
  name: string;
  url: string;
}

type MusicType = {
  uri: string;
  song_url: string;
  external_link: string;
  name: string;
  duration_ms: number;
  artists: Array<ArtistType>,
  image_1x1: string;
}

@Options({
  data() {
    return {
      progress: 0,
    }
  },
  computed: {
    ...mapGetters('playable', ['specificPlayerPlaying']),
  },
  methods: {
    getPlayerId() {
      return this.music.uri + ':' + this.custom_identifier
    },
    getPlayerAndStatus() {
      const playerId = this.getPlayerId()
      const players = this.$store.getters['playable/getPlayers'];
      let player: HTMLAudioElement
      if(players && Object.keys(players).includes(playerId)) {
        player = players[playerId]
      } else player = new Audio(this.music.song_url)
      let is_playing = this.specificPlayerPlaying(playerId)
      const playerPlayings = this.$store.getters['playable/getPlayerIds'];
      if(playerPlayings && Object.keys(playerPlayings).includes(playerId)) is_playing = playerPlayings[playerId]
      return {is_playing, player, playerId}
    },
    async playOrPause() {
      const {is_playing, player} = this.getPlayerAndStatus()
      if(is_playing) {
        player.pause()
        this.update(player, !is_playing)
      } else {
        this.$store.commit('playable/stopAllPlayer')
        await this.$sleep(600)
        player.play().then((r: HTMLAudioElement) => this.$log.log(r))
        this.update(player, !is_playing)
      }
      player.addEventListener('timeupdate', this.timeUpdate, false);
    },
    update(player: HTMLAudioElement, is_playing = false) {
      this.$store.commit('playable/updatePlaying', {
        player,
        player_id: this.getPlayerId(),
        is_playing,
      })
    },
    timeUpdate() {
      const {player, playerId} = this.getPlayerAndStatus()
        this.$log.log('updating...')
        this.progress = (((player.currentTime) / (player.duration)) * 100) || 0
        if(player.currentTime >= parseInt(player.duration)){
          this.stopAndUnsetPlayer(player, playerId)
        }
    },
    stopAndUnsetPlayer(player: HTMLAudioElement, playerId: string) {
      player.pause()
      player.removeEventListener('timeupdate', this.timeUpdate, false);
      player.currentTime = 0
      this.update(player)
      this.$store.commit('playable/unsetPlayer', playerId)
    }
  },
  components: {Button, Pause, Play, BrowserLink},
  props: {
    music: Object,
    custom_identifier: String,
  },
  beforeUnmount() {
    const {player, playerId} = this.getPlayerAndStatus()
    this.stopAndUnsetPlayer(player, playerId)
  }
})
export default class extends Vue {
  music!: MusicType;
  post_created_at!: string;
}
