
import { Options, Vue } from 'vue-class-component'
import ItemWrap from '../ItemWrap.vue'
import FullScreenIcon from '@/components/icons/FullScreen.vue'

@Options({
     components: {
          ItemWrap,
          FullScreenIcon
     }
})
export default class More extends Vue {}
