import axios from '@/ts/initAxios'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

export type User = {
     id: number,
     username: string,
     display_name: string,
     email: string,
     is_private: boolean,
     profile_image_path: string,
     bio: string|null,
     gender: string|null,
     birth_date: string|null,
     email_verified_at: string|null,
     created_at: string,
     updated_at: string,
     followers_count: number,
     followings_count: number,
     questions_count: number,
     answers_count: number,
     is_premium: boolean;
}

export type State = {
     isAuthorized: boolean,
     user: User|null,
}

const state: State = {
     isAuthorized: false,
     user: null,
}

const getters = {
     isAuthenticated: (state: State): boolean => !!state.user && state.isAuthorized,
     user: (state: State): User|null => state.user,
}
   

const actions = {
     async fetchUser(context: any) {
          let res
          try {
               res = await axios.get('/me')
          } catch(e: AxiosError|unknown) {
               console.error('Unauthorized')
          }

          if(res?.status == 200 && res.data.user) {
               context.state.user = res.data.user
               context.state.isAuthorized = true
          } else {
               context.state.user = null
               context.state.isAuthorized = false
          }
     },
}

const mutations = {
     setUserLoggedIn(state: State, user: User) {
          state.isAuthorized = true
          state.user = user
     },
     logout(state: any) {
          state.user = null
          state.isAuthorized = false
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}