<template>
     <div class="lg:grid grid-cols-2 grid-rows-1 gap-3 space-y-3 lg:space-y-0">
          <div class="transition duration-150 bg-red-300 hover:bg-red-200 dark:bg-red-400 dark:hover:bg-red-300 p-3 px-5 rounded-2xl cursor-pointer">
               <h4 class="appFont text-3xl text-white">3 days</h4>
               <span class="text-sm">{{$t('Current streak')}}</span>
          </div>
          <div class="transition duration-150 bg-green-300 hover:bg-green-200 dark:bg-green-400 dark:hover:bg-green-300 p-3 px-5 rounded-2xl cursor-pointer">
               <h4 class="appFont text-3xl text-white">x1.5</h4>
               <span class="text-sm">{{$t('Point Multiplier')}}</span>
          </div>
     </div>
</template>