import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full lg:translate-x-0"
}
const _hoisted_2 = { class: "mt-20 h-full px-3 py-4 overflow-y-auto" }
const _hoisted_3 = { class: "space-y-2 font-medium" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHomeIcon = _resolveComponent("NavHomeIcon")!
  const _component_SideBarButton = _resolveComponent("SideBarButton")!
  const _component_NavHeartIcon = _resolveComponent("NavHeartIcon")!
  const _component_NavClockIcon = _resolveComponent("NavClockIcon")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_NavMessagesIcon = _resolveComponent("NavMessagesIcon")!

  return (_ctx.isAuth)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", null, [
              _createVNode(_component_SideBarButton, {
                to: "/app",
                title: _ctx.$t('Home')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NavHomeIcon)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_SideBarButton, {
                to: "/app/activity",
                title: _ctx.$t('Activity')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NavHeartIcon)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_SideBarButton, {
                to: _ctx.canUpload ? '/app/upload' : '#',
                title: "Upload"
              }, {
                default: _withCtx(() => [
                  (!_ctx.canUpload)
                    ? (_openBlock(), _createBlock(_component_NavClockIcon, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_Plus, { key: 1 }))
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_SideBarButton, {
                to: "/app",
                title: _ctx.$t('Chats')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NavMessagesIcon)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_SideBarButton, {
                to: "/me",
                title: _ctx.$t('My Profile')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "w-[34px] p-1 text-center mx-auto rounded-full",
                    src: _ctx.$profileImage(_ctx.user.profile_image_path)
                  }, null, 8, _hoisted_4)
                ]),
                _: 1
              }, 8, ["title"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}