
import Song from "@/components/icons/Song.vue";
import Voice from "@/components/icons/Voice.vue";
import Gif from "@/components/icons/Gif.vue";
import {Options, Vue} from "vue-class-component";
import Image from "@/components/icons/Image.vue";

@Options({
  data() {
    return {
      contents: [
        {
          key: 'image',
          name: this.$t('Image'),
          icon: Image,
          premium: false,
        },
        {
          key: 'music',
          name: this.$t('Music'),
          icon: Song,
          premium: true,
        },
        {
          key: 'voice',
          name: this.$t('Voice recording'),
          icon: Voice,
          premium: true,
        },
        {
          key: 'gif',
          name: this.$t('Gif'),
          icon: Gif,
          premium: false,
        }
      ],
      selected: null,
    }
  },
  methods: {
    select(content: any) {
      if(content.key == this.selected) this.selected = null
      else this.selected = content.key
      window.location.hash = content.key
      this.$emit('changed', content)
    }
  }
})
export default class extends Vue {}
