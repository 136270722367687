import { Network } from '@capacitor/network';
import store from '@/store';

Network.addListener('networkStatusChange', status => {
     store.commit('network/setStatus', status)
});

export default async () => {
     const status = await Network.getStatus();
     store.commit('network/setStatus', status)
};