
import { Options, Vue } from 'vue-class-component';
import AppLayout from '@/components/layouts/AppLayout.vue'
import TopNav from '@/components/navs/TopNav.vue'

@Options({
     components: {
          AppLayout,
          TopNav
     },
     props: {
          disableTopNav: Boolean,
          classList: Array||null,
          removePadding: Boolean||null,
     },
})
export default class AuthorizedLayout extends Vue {
     disableTopNav!: boolean;
     classList!: Array<string>|null;
     removePadding!: boolean|null;
}
