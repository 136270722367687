<template>
     <svg
          class="svg-inline--fa fa-w-16"
          style="enable-background: new 0 0 24 24"
          version="1.1"
          viewBox="0 0 24 24"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          >
          <g id="Guides" />
          <g id="_x32_0" />
          <g id="_x31_9" />
          <g id="_x31_8" />
          <g id="_x31_7" />
          <g id="_x31_6" />
          <g id="_x31_5" />
          <g id="_x31_4" />
          <g id="_x31_3" />
          <g id="_x31_2" />
          <g id="_x31_1" />
          <g id="_x31_0" />
          <g id="_x30_9" />
          <g id="_x30_8" />
          <g id="_x30_7" />
          <g id="_x30_6" />
          <g id="_x30_5" />
          <g id="_x30_4" />
          <g id="_x30_3" />
          <g id="_x30_2" />
          <g id="_x30_1">
               <g>
               <path
                    d="M13,5.569458V4.5h1c0.5522461,0,1-0.4477539,1-1s-0.4477539-1-1-1h-4c-0.5522461,0-1,0.4477539-1,1s0.4477539,1,1,1h1    v1.069458C7.0600586,6.0640869,4,9.4282227,4,13.5c0,4.4111328,3.5888672,8,8,8s8-3.5888672,8-8    C20,9.4282227,16.9399414,6.0640869,13,5.569458z M12,19.5c-3.3085938,0-6-2.6914063-6-6s2.6914063-6,6-6s6,2.6914063,6,6    S15.3085938,19.5,12,19.5z"
               />
               <path
                    d="M13.2929688,10.7929688l-2,2c-0.390625,0.390625-0.390625,1.0234375,0,1.4140625    C11.4882813,14.4023438,11.7441406,14.5,12,14.5s0.5117188-0.0976563,0.7070313-0.2929688l2-2    c0.390625-0.390625,0.390625-1.0234375,0-1.4140625S13.6835938,10.4023438,13.2929688,10.7929688z"
               />
               </g>
          </g>
     </svg>
</template>

<style scoped>
path {
  transition: fill 0.5s;
  fill: currentColor;
}

a[active="true"] svg {
  fill: currentColor;
}

g {
  fill: currentColor;
}
</style>