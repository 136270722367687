import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-364e1100"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-left"
}
const _hoisted_2 = {
  key: 0,
  class: "titleFont text-2xl mb-3"
}
const _hoisted_3 = {
  key: 1,
  class: "-mt-5 text-red-400"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "relative items-center pb-3" }
const _hoisted_6 = { class: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-300 -mt-2.5" }
const _hoisted_7 = ["onUpdate:modelValue", "type", "placeholder"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "text-red-400 mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIcon = _resolveComponent("UserIcon")!
  const _component_LockIcon = _resolveComponent("LockIcon")!
  const _component_EmailIcon = _resolveComponent("EmailIcon")!
  const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (input) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: input.name
      }, [
        (input.name == _ctx.current_field.name)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (input.title)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(input.title), 1))
                : _createCommentVNode("", true),
              (input.name in _ctx.errors)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors[input.name], (error, i) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                        _createTextVNode(_toDisplayString(error) + " ", 1),
                        (i+1 < _ctx.errors[input.name].length)
                          ? (_openBlock(), _createElementBlock("br", _hoisted_4))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (input.icon == 'user')
                    ? (_openBlock(), _createBlock(_component_UserIcon, { key: 0 }))
                    : _createCommentVNode("", true),
                  (input.icon == 'lock')
                    ? (_openBlock(), _createBlock(_component_LockIcon, { key: 1 }))
                    : _createCommentVNode("", true),
                  (input.icon == 'email')
                    ? (_openBlock(), _createBlock(_component_EmailIcon, { key: 2 }))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.formFields[input.name]) = $event),
                  type: input.type,
                  placeholder: input.placeholder,
                  class: "transition-colors duration-150 block w-full px-4 py-3 pl-10 text-sm rounded-lg border-2 text-gray-600 bg-gray-100 border-gray-100 hover:bg-gray-200 hover:border-gray-200 focus:bg-gray-200 focus:border-gray-200 dark:text-gray-200 dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:bg-gray-700 dark:focus:border-gray-700 md:text-lg"
                }, null, 8, _hoisted_7), [
                  [_vModelDynamic, _ctx.formFields[input.name]]
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_ctx.hasSlotBeforeSubmitButton && false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "default", { class: "my-5" }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "bounce" }, {
      default: _withCtx(() => [
        (_ctx.formFields[_ctx.current_field.name] && _ctx.formFields[_ctx.current_field.name].length > 0)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _ctx.startAction,
              type: "submit",
              isLoading: _ctx.processing
            }, {
              default: _withCtx(() => [
                (_ctx.hasNext)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createTextVNode(_toDisplayString(_ctx.$t('OK? Click here')) + " ", 1),
                      _createVNode(_component_ArrowRightIcon)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.hasNext)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.btnTitle || _ctx.$t('Submit')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick", "isLoading"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 32))
}