import isApp from '@/ts/mobile/isApp'
import { App as CapacitorApp } from '@capacitor/app';

export default function () {
     if(isApp()) CapacitorApp.addListener('backButton', ({ canGoBack }) => {
          if(!canGoBack){
          CapacitorApp.exitApp();
          } else {
          window.history.back();
          }
     })
}