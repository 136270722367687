
import { Options, Vue } from 'vue-class-component';
import Spinner from "@/components/icons/Spinner.vue";

@Options({
  components: {Spinner},
     props: {
          count: Number || null,
          title: String || null
     }
})
export default class MePageUserFollowDataComponent extends Vue {
     count!: number|null;
     title!: string|null;
}
