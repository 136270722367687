
import { Options, Vue } from 'vue-class-component';
import UserSheet from "@/components/home/sheets/UserSheet.vue";
import premiumColors from "@/components/me/premiumColors";
import Premium from "@/components/icons/Premium.vue";
import UserName from "@/components/home/user/UserName.vue";
import UserProfileImage from "@/components/home/user/UserProfileImage.vue";
import Dots from "@/components/icons/Dots.vue";

@Options({
  components: {Dots, UserProfileImage, UserName, Premium, UserSheet},
  data() {
      return {
        premiumColors,
        showSheet: false,
      }
  },
  props: {
    user: Object,
    is_anonymous: Boolean,
    posted_at: String,
  },
  computed: {
    premiumCubeImage() {
      return(
          !this.is_anonymous && this.user && this.user.is_premium && this.user.premium_settings &&
          this.user.premium_settings.cube_profile_image
      )
    }
  }
})
export default class extends Vue {
  user!: object;
  is_anonymous!: boolean;
  posted_at!: string;
}
