
import { Options, Vue } from 'vue-class-component';
import { Sheet as SheetUtil } from 'bottom-sheet-vue3'

@Options({
     data() {
          return {
               suVisible: false,
               idkWhatItCalledSize: {
                    marginTop: '-2.5rem'
               },
          }
     },
     components: {
          SheetUtil
     },
     props: {
          addPadX: Boolean,
          addHeader: Boolean,
          visibility: Boolean,
     },
     mounted() {
          this.suVisible = this.visibility;          
     },
    unmounted() {
      this.$theme.navbarColor(this.$route?.meta?.theme?.navbarColorIndex)
      this.$theme.save()
      document.body.style.overflow = 'auto';
      this.$emit('close')
    },
     watch: {
          visibility: async function() {
               this.suVisible = this.visibility;
               if(this.suVisible) {
                    document.querySelectorAll('[data-tippy-root]').forEach(el => el.remove())
                    document.body.style.overflow = 'hidden';
                    this.$theme.navbarColor(0)
                    this.$theme.save()
                    await this.$sleep(300)
                    if(Object.keys(this.$refs).includes('headRef')) {
                         const headerSize = this.$refs.headRef.offsetHeight
                         this.idkWhatItCalledSize = {
                              marginTop: `${(headerSize-headerSize*2)-15}px`
                         }
                    }
               }
          },
          suVisible: function() {
               if(this.suVisible == false) {
                    this.$theme.navbarColor(this.$route?.meta?.theme?.navbarColorIndex)
                    this.$theme.save()
                    document.body.style.overflow = 'auto';
                    this.$emit('close')
               }
          }
     }
})
export default class Sheet extends Vue {
     addPadX !: boolean;
     addHeader !: boolean;
     visibility !: boolean;
}
