import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-618ff422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-[90%] my-3 mx-auto flex flex-wrap text-left items-center" }
const _hoisted_2 = { class: "w-full text-center" }
const _hoisted_3 = { class: "appFont mt-3 cursor-pointer space-x-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserProfileImage = _resolveComponent("UserProfileImage")!
  const _component_UserName = _resolveComponent("UserName")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createBlock(_component_Sheet, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    addHeader: _ctx.addImageHeader && false,
    visibility: _ctx.showSheet
  }, {
    header: _withCtx(() => [
      (_ctx.addImageHeader)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "h-[125px] w-full icover rounded-t-lg",
            style: _normalizeStyle({
                    backgroundImage: `url(null)`
               })
          }, null, 4))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("section", {
            class: _normalizeClass({'-mt-14 absolute z-[50] my-3 w-[90%]': _ctx.addImageHeader && false})
          }, [
            _createVNode(_component_UserProfileImage, {
              class: "mx-auto w-20",
              user: _ctx.user,
              show_is_online: false
            }, null, 8, ["user"]),
            _createElementVNode("p", _hoisted_3, [
              _createVNode(_component_UserName, { user: _ctx.user }, null, 8, ["user"])
            ])
          ], 2),
          _createVNode(_component_Separator),
          (_ctx.user)
            ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
                _createVNode(_component_Button, {
                  class: "my-1",
                  "gray-theme": true
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Report')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_LinkButton, {
                  class: "w-full",
                  to: '/user/' + _ctx.user.username
                }, {
                  default: _withCtx(() => [
                    (!_ctx.followed)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "my-1 w-full"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('View Profile')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_Separator),
                (!_ctx.followed)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "my-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Follow')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.followed)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      class: "my-1",
                      "gray-theme": true
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Unfollow")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.user.is_private && _ctx.requested)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 2,
                      class: "my-1",
                      "gray-theme": true
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Requested')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("section", _hoisted_5, [
                _createVNode(_component_Button, {
                  class: "my-1",
                  "gray-theme": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Spinner)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Separator),
                _createVNode(_component_Button, { class: "my-1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Spinner)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  class: "my-1",
                  "gray-theme": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Spinner)
                  ]),
                  _: 1
                })
              ]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["addHeader", "visibility"]))
}