
import { defineComponent } from 'vue'
import SaveIcon from '@/components/icons/Save.vue'
import HeartIcon from '@/components/icons/Heart.vue'
import ShareIcon from '@/components/icons/Share.vue'
import ShareSheet from './ShareSheet.vue'

export default defineComponent({
     data() {
          return {
               shareSheet: false,
               isLiked: false,
               isSaved: false,
          }
     },
     components: {
          SaveIcon,
          HeartIcon,
          ShareIcon,
          ShareSheet
     },
     props: {
          flexRow: Boolean,
          answer: Object,
     }
})
