import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-3xl" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm -mt-1 appFont"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createBlock(_component_LinkButton, {
    active: _ctx.active,
    sleep: 5,
    class: _normalizeClass(["transition mx-auto duration-150 p-3 w-full ripple items-center", {
          'text-primary-300 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-300': _ctx.active && !_ctx.disableIconColors,
          'text-gray-300 dark:text-white': !_ctx.active && !_ctx.disableIconColors,
          'hover:text-primary-300':_ctx.showNames && !_ctx.disableIconColors,
          'text-gray-300 dark:text-gray-700 hover:text-gray-400 dark:hover:text-gray-600': !_ctx.showNames && !_ctx.disableIconColors
     }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (_ctx.showNames && _ctx.name)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.name), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["active", "class"]))
}