import __dev_log from "@/ts/__dev_log";

const toStyle = (style: Array<string>, merge: Array<string> = []): string => {
     return [...style, ...merge].join(';')
}

const styles: Array<string> = [
     'color: #ffb302;',
     'font-size: 70px',
     'font-weight: 600',
     'padding: 10px'
]

const warn = () => {
     if(process.env.NODE_ENV === 'development') return
     __dev_log.log('%cHey!', toStyle(styles));
     __dev_log.log('%cNever paste unknown scripts here! Attackers can use the attack called "self-xss" to gain access to your WiZZL account!', toStyle(styles, [
          'font-size: 30px',
          'color: #fd3333'
     ]))
     setTimeout(() => warn(), 60000 * 5)
}

export default warn;