
import Box from "@/components/settings/Box.vue";
import Group from "@/components/settings/Group.vue";
import Switch from "@/components/utils/Switch.vue";
import PencilIcon from "@/components/icons/Pencil.vue";
import PauseIcon from "@/components/icons/Pause.vue";
import TrashIcon from "@/components/icons/Trash.vue";
import LockIcon from '@/components/icons/Lock.vue';
import {Options, Vue} from "vue-class-component";

@Options({
  data() {
      return {
        lockIcon: <LockIcon/>,
        pencilIcon: <PencilIcon/>,
        pauseIcon: <PauseIcon/>,
        trashIcon: <TrashIcon class="!text-red-400" />
      }
  },
  components: {
    Box,
    Group,
    Switch
  },
  methods: {
    async switchAccountType() {
      const user = this.user()
      user.is_private = !user.is_private
      const is_private = user.is_private
      await this.axiosHE.put('/user/account_visibility', {is_private})
      this.$store.commit('auth/setUserLoggedIn', user)
    }
  }
})
export default class AccountPage extends Vue {}
