import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "my-2 py-3" }
const _hoisted_2 = {
  key: 0,
  class: "bg-gray-900 md:flex items-center rounded-t-lg mx-4"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "w-full px-5 py-3"
}
const _hoisted_5 = { class: "sm:flex sm:items-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "w-auto ml-0 sm:ml-auto" }
const _hoisted_8 = { class: "titleFont text-3xl text-gray-700 dark:text-gray-400 mt-3 sm:mt-0" }
const _hoisted_9 = { class: "titleFont text-lg" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "p-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Actions = _resolveComponent("Actions")!
  const _component_UserProfile = _resolveComponent("UserProfile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.a.asset)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (['image', 'gif'].includes(_ctx.a.type))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "rounded-t w-full",
                src: _ctx.$cdn(_ctx.a.asset.image)
              }, null, 8, _hoisted_3))
            : (_ctx.a.type == 'spotify')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: _ctx.a.asset.cover_image,
                      class: "w-auto sm:w-[100px] sm:h-[100px] rounded-lg mx-auto my-2 sm:my-0 sm:mx-0"
                    }, null, 8, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.a.asset.music_name), 1),
                      _createElementVNode("h4", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.a.asset.artists, (artist, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            _createElementVNode("span", {
                              class: _normalizeClass({'text-sm sm:text-md dark:text-gray-500': index !== 0})
                            }, _toDisplayString(artist), 3),
                            (index < _ctx.a.asset.artists.length-2 && index != _ctx.a.asset.artists.length-1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", "))
                              : (index != _ctx.a.asset.artists.length-1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, " & "))
                                : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["bg-gray-900 rounded-b-lg py-2 mx-4", {'rounded-t-lg':!_ctx.a.asset}])
    }, [
      (_ctx.a.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", {
              class: _normalizeClass(["cursor-pointer", {'text-3xl': _ctx.a.description.length < 11, 'text-xl': _ctx.a.description.length < 30, '!text-md': _ctx.a.asset}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copy(_ctx.a.description)))
            }, _toDisplayString(_ctx.a.description), 3)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Actions, { answer: _ctx.a }, null, 8, ["answer"]),
      _createVNode(_component_UserProfile, {
        user: _ctx.a.user,
        is_anonymous: _ctx.a.is_anonymous,
        posted_at: _ctx.a.created_at
      }, null, 8, ["user", "is_anonymous", "posted_at"])
    ], 2)
  ]))
}