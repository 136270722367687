
import Button from "@/components/auth/Button.vue";
import Input from './Input.vue';
import {Options, Vue} from "vue-class-component";
import Spinner from "@/components/icons/Spinner.vue";
import Separator from "@/components/Separator.vue";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import Lock from "@/components/icons/Lock.vue";
import BioLinkMeta from "@/components/utils/BioLinkMeta.vue";
import Refresh from "@/components/icons/Refresh.vue";
import Trash from "@/components/icons/Trash.vue";

@Options({
  data() {
    return {
      fetching: false,
      show: false,
      processing: false,
      form: {},
      errors: {},
      links: [],
      canAddMore: true,
      refreshing_ids:[],
      deleting_ids:[],
    }
  },
  components: {
    Trash,
    Refresh,
    BioLinkMeta,
    Lock,
    BrowserLink,
    Separator,
    Spinner,
    Button,
    Input
  },
  async beforeMount() {
    this.form = this.$wForm('/bio_link/', {real_url:''})
    await this.fetchLinks()
  },
  methods: {
    async submit() {
      this.processing = true
      await this.form.submit()
      this.processing = false
      this.errors = this.form.errors
      if(this.form.success) {
        this.form.fields.real_url = ''
        this.fetchLinks()
      }
    },
    async fetchLinks() {
      this.fetching = true
      let res
      try {
        res = await this.axios.get('/bio_link')
      } catch(e) {
        this.$router.push('/')
      }
      const links = res.data.bio_links || {}
      this.links = links
      this.fetching = false
      this.canAddMore = res.data.can_add_more
    },
    appUrl(url: string) {
      return window.config?.server?.LINK_REDIRECT_URL?.replace(':id', url)
    },
    async refresh(link: any) {
      this.refreshing_ids.push(link.id)
      let error = false
      try {
        await this.axios.patch(`/bio_link/${link.id}`)
      } catch(e: any) {
        console.error(e.message)
        this.$toast.error(this.$t('error.unknown'))
        error = true
      }
      const index = this.refreshing_ids.indexOf(link.id)
      if (index !== -1) {
        this.refreshing_ids.splice(index, 1);
      }
      if(!error) this.$toast.info(this.$t('Request successfully sent, it takes time to make the actual change'))
    },
    async destroy(link: any) {
      this.deleting_ids.push(link.id)
      let error = false
      try {
        await this.axios.delete(`/bio_link/${link.id}`)
      } catch(e: any) {
        console.error(e.message)
        this.$toast.error(this.$t('error.unknown'))
        error = true
      }
      const index = this.deleting_ids.indexOf(link.id)
      if (index !== -1) {
        this.deleting_ids.splice(index, 1);
        if(!error) this.$toast.success(this.$t('Successfully deleted'))
      }
      this.fetchLinks()
    }
  }
})
export default class AppBioLinksManager extends Vue {}
