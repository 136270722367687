
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'
import Separator from '@/components/Separator.vue';
import vibrate from '@/ts/mobile/vibrate'

@Options({
     data() {
          return {
               inputData: 'This is a font for the WiZZL app ;)'
          }
     },
     components: {
          Sheet,
          Separator
     },
     methods: {
          async onInput() {
               if(this.inputData == '') {
                    vibrate(null, 100)
                    this.inputData = 'This is a font for WiZZL app ;)'
               }
          }
     },
     props: {
          showSheet: Boolean,
     },
})
export default class VibrationSheetComponent extends Vue {
     showSheet!: boolean;
}
