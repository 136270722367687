
import { Options, Vue } from 'vue-class-component';
import BackPage from '@/components/BackPage.vue'
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Page from '@/components/users/Page.vue'

@Options({
  data() {
      return {
        loaded: false,
        userExists: false,
        display_name: null,
      }
  },
  components: {
    BackPage,
    Layout,
    Page,
  },
  mounted() {
    if(this.$route.params.username == this.user().username) {
      this.$toast.info(this.$t('Your public view') + ' ;)')
      //this.$router.push('/me')
    }
  }
})
export default class MeView extends Vue {}
