
import { Options, Vue } from 'vue-class-component'
import Button from './Button.vue'
import Sheet from '@/components/utils/Sheet.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

@Options({
     data() {
          return {
               opt: 0,
               visibility: false,
               selected: []
          }
     },
     methods: {
          addOrRemove(tag: string){
               if(!this.selected.includes(tag)) {
                    this.selected.push(tag)
               }
               else {
                    const index = this.selected.indexOf(tag);
                    if (index > -1) {
                         this.selected.splice(index, 1);
                    }
               }
               this.$emit('selected', this.selected)
          },
     },
     components: {
          Button,
          Sheet,
          CloseIcon
     },
     props: {
          min: Number,
          max: Number,
          dataset: Object,
          selects: Array,
     },
     mounted() {
          this.selected = this.selects || []
     }
})
export default class TextInput extends Vue {
     min!: number;
     max!: number;
     dataset!: object;
     selects!: Array<string>;
}
