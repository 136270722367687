import axios from '@/ts/initAxios'
import { AxiosError } from "axios";
import store from '@/store';

const profileSetupState = async () => {
     if(store.getters['auth/isAuthenticated']){
          let res
          try {
               res = await axios.get('/profile-setup-state')
          } catch(e: AxiosError|unknown) {
               console.error('Unable to fetch profile setup state', e)
          }

          if(res?.status == 200 && res.data) {
               const data = res.data
               let isRequired = false
               Object.keys(res.data).forEach((key: any) => {
                    if(data[key].require) isRequired = true
               })
               store.commit('profileSetupState/setDataset', {
                    dataSet: res.data,
                    isRequired
               })
          } else console.error('Cannot get user profile state')
     }
}

export default profileSetupState;