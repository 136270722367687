export type Players = {
    [index: string]: HTMLAudioElement;
}

export type IsPlayings = {
    [index: string]: boolean;
}

export type State = {
    players: Players;
    is_playings: IsPlayings;
}

const state: State = {
    players: {},
    is_playings: {},
}

const getters = {
    getPlayers: (state: State): Players => state.players,
    getPlayerPlayings: (state: State): IsPlayings => state.is_playings,
    specificPlayerPlaying: (state: State) => (id: string) => {
        return Object.keys(state.is_playings).includes(id) ? state.is_playings[id] : false
    }
}

const actions = {

}

const mutations = {
    addPlayer(state: State, music: { player: HTMLAudioElement; player_id: string; is_playing: boolean|null; }):void {
        state.players = {}
        state.players[music.player_id] = music.player
        state.is_playings[music.player_id] = !!music.is_playing;
    },
    updatePlaying(state: State, config: { player: HTMLAudioElement; player_id: string; is_playing: boolean; }):void {
        if(!Object.keys(state.players).includes(config.player_id) || !Object.keys(state.is_playings).includes(config.player_id)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.commit('playable/addPlayer', {player: config.player, player_id: config.player_id, is_playing: config.is_playing})
        }
        state.players[config.player_id] = config.player
        state.is_playings[config.player_id] = config.is_playing
    },
    /**
     * Unset music player by id (if not stopped, it'll stop playing)
     * @param state
     * @param player_id
     */
    unsetPlayer(state: State, player_id: string):void {
        let stopPlayer = false
        if(Object.keys(state.is_playings).includes(player_id)) {
            if(state.is_playings[player_id]) stopPlayer = true
            delete state.is_playings[player_id]
        }
        if(Object.keys(state.players).includes(player_id)) {
            if(stopPlayer) state.players[player_id].pause()
            delete state.players[player_id]
        }
    },
    stopAllPlayer: function (state: State) {
        const players = state.players
        const is_playings = state.is_playings
        for(const id in players) {
            if(Object.keys(is_playings).includes(id) && is_playings[id]) {
                players[id].pause()
                is_playings[id] = false
            }
        }
    }
}

const namespaced = true;

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}