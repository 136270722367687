import { Haptics, ImpactStyle } from '@capacitor/haptics';
import store from '@/store';

const types: any = {
     'light': ImpactStyle.Light,
     'medium': ImpactStyle.Medium,
     'heavy': ImpactStyle.Heavy,
}

const vibrate = async (type:string|null = null, duration = 300) => {
     if(type == null) type = store.getters['settings/getVibrationStyle']
     if(type == null) type = 'medium'
     const style = types[type.toLowerCase()] || ImpactStyle.Medium
     await Haptics.impact({ style });
     await Haptics.vibrate({ duration })
}

export default vibrate;