
import { Options, Vue } from 'vue-class-component';
import Spinner from "@/components/icons/Spinner.vue";
import UserFollowBox from "@/components/me/UserFollowBox.vue";
import Separator from "@/components/Separator.vue";
import Button from "@/components/auth/Button.vue";
import Expand from "@/components/icons/Expand.vue";
import Lock from "@/components/icons/Lock.vue";
import LinksSheet from "@/components/users/LinksSheet.vue";
import Dots from "@/components/icons/Dots.vue";
import Premium from "@/components/icons/Premium.vue";
import {Clipboard} from "@capacitor/clipboard";
import Copy from "@/components/icons/Copy.vue";
import Guess from "@/components/users/Guess.vue";
import LazyImage from "@/components/utils/LazyImage.vue";
import ProfileImageLazy from "@/components/users/ProfileImageLazy.vue";

@Options({
  data() {
    return {
      linksSheet: false,
      bioLimit: true,
      loaded: false,
      failed: false,
      not_found: false,
      user: {
        followers_count: null,
        followings_count: null,
        points: null
      }
    }
  },
  computed: {
    bio() {
      if(!this.user.bio) return false
      let bio: string = this.user.bio.htmlescape()
      bio = bio.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
          .replace(/\*(.*?)\*/g, '<i>$1</i>')
      return bio
    },
    premiumCubeImage() {
      return(
          this.user.is_premium && this.user.premium_settings &&
          this.user.premium_settings.cube_profile_image
      )
    }
  },
  components: {
    ProfileImageLazy,
    LazyImage, Guess, Premium, Dots, LinksSheet, Lock, Expand, Button, Separator, UserFollowBox, Spinner},
  methods: {
    url(url: string) {
      return new URL(url)
    },
    async loadUser() {
      if(!this.$route.params.username) return
      let res
      try {
        res = await this.axios.get('/user/' + this.$route.params.username)
      } catch(e: any) {
        console.error(e.message)
        if(e.response.status == 404) {
          this.$toast.error('User not found')
          this.not_found = true
        } else {
          this.$toast.error('An unknown error occurred')
        }
        this.failed = true
        return
      }
      if(res.data.user) {
        this.user = res.data.user
        this.loaded = true
        this.$emit('hasName', this.user.display_name)
      } else {
        this.failed = true
        this.$toast.error('An unknown error occurred')
      }
    },
    async copyUsername() {
      await Clipboard.write({
        string: this.user.username
      })
      this.$toast.info('Successfully copied', {
        icon: Copy
      })
    }
  },
  async mounted() {
    await this.loadUser()
  },
  watch: {
    '$route.params.username': function() {
      this.linksSheet = false
      this.bioLimit = true
      this.loaded = false
      this.failed = false
      this.not_found = false
      this.user = {}
      this.loadUser()
    }
  }
})
export default class extends Vue {}
