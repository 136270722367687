import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-right pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InAppLink = _resolveComponent("InAppLink")!
  const _component_Form = _resolveComponent("Form")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createBlock(_component_AuthLayout, {
    title: _ctx.$t('We are happy to have you back') + ' 😁'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        onSuccess: _ctx.authUser,
        formApi: "/login",
        btnTitle: _ctx.$t('Sign in'),
        hasSlotBeforeSubmitButton: true,
        fields: _ctx.fields
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_InAppLink, { to: "/auth/forgot-password" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Forgot password?')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onSuccess", "btnTitle", "fields"])
    ]),
    _: 1
  }, 8, ["title"]))
}