
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";

@Options({
  computed: {
    ...mapGetters({
      requestProgress: 'requestLoader/getProgress',
      requestFailed: 'requestLoader/isFailed',
      requestWorking: 'requestLoader/isWorking'
    })
  }
})
export default class RequestLoader extends Vue {}
