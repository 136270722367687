import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "mx-auto w-fit text-center" }
const _hoisted_3 = { class: "appFont -ml-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Back = _resolveComponent("Back")!
  const _component_InAppLink = _resolveComponent("InAppLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_InAppLink, {
        to: _ctx.to ? _ctx.to : '/'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Back)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}