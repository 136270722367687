import serverLoadSettings from "../settings";
import onError from './errors'
import isApp from "../mobile/isApp";
import warn from "./consoleWarns";
//import bg from "@/ts/mobile/background/bg";
import profileSetupState from "../settings/profileSetupState";
import setBottomNavbar from "@/ts/mobile/setBottomNavbar";

const loadUp = async () => {
     document.querySelector('html')?.setAttribute(
          'frontend-is-native',
          isApp() ? 'true' : 'false'
     )
     warn()
     onError()
     await serverLoadSettings()
     setBottomNavbar()
     await profileSetupState()
     //await bg()
}

export default loadUp;