
import { Options, Vue } from 'vue-class-component'
import ErrorIcon from '@/components/icons/Error.vue'
import Button from '@/components/auth/Button.vue'
import BrowserLink from "@/components/utils/BrowserLink.vue";
import Server from "@/components/icons/Server.vue";

@Options({
     components: {
       Server,
       BrowserLink,
          ErrorIcon,
          Button
     },
     props: {
          message: String || null,
          help_url: String || null,
          server_error: Boolean,
     },
     methods: {
          retry() {
               this.$router.go(0)
          }
     }
})

export default class MainLoader extends Vue {
     message !: string|null;
     help_url!: string|null;
     server_error!: boolean;
}
