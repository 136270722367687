import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createBlock(_component_Group, {
    title: _ctx.$t('Sessions and tokens')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Box, {
        title: _ctx.$t('Manage sessions'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/me/edit'))),
        icon: _ctx.keyIcon
      }, null, 8, ["title", "icon"]),
      _createVNode(_component_Box, {
        title: _ctx.$t('IP Auth'),
        icon: _ctx.addressIcon,
        useSlot: true,
        desc: _ctx.$t('Access to your account using only IP addresses that are already permitted (turning this off may increase the risk of the profile being hacked)')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Switch, {
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$toast.warning(_ctx.$t('Not implemented'))))
          })
        ]),
        _: 1
      }, 8, ["title", "icon", "desc"])
    ]),
    _: 1
  }, 8, ["title"]))
}