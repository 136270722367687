
import { Options, Vue } from 'vue-class-component';
import Layout from '@/components/layouts/AuthorizedLayout.vue'

@Options({
  components: {
    Layout,
  },
})
export default class ChatsView extends Vue {}
