import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-xl pt-4 appFont" }
const _hoisted_2 = { class: "overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createBlock(_component_Sheet, {
    onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'))),
    addHeader: true,
    visibility: _ctx.showSheet,
    addPadX: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('Fonts')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Separator, { class: "w-[90%] mx-auto" }),
      _createElementVNode("main", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputData) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          class: "w-full text-4xl cursor-pointer bg-white dark:bg-gray-900 hover:bg-gray-200 focus:bg-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-800 p-1 py-2"
        }, null, 544), [
          [
            _vModelText,
            _ctx.inputData,
            void 0,
            { lazy: true }
          ]
        ]),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputData) = $event)),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          class: "titleFont w-full text-4xl cursor-pointer bg-white dark:bg-gray-900 hover:bg-gray-200 focus:bg-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-800 p-1 py-2"
        }, null, 544), [
          [
            _vModelText,
            _ctx.inputData,
            void 0,
            { lazy: true }
          ]
        ]),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputData) = $event)),
          onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          class: "appFont w-full text-4xl cursor-pointer bg-white dark:bg-gray-900 hover:bg-gray-200 focus:bg-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-800 p-1 py-2"
        }, null, 544), [
          [
            _vModelText,
            _ctx.inputData,
            void 0,
            { lazy: true }
          ]
        ])
      ])
    ]),
    _: 1
  }, 8, ["visibility"]))
}