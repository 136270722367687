
import { Options, Vue } from 'vue-class-component';
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Page from '@/components/settings/Page.vue'
import BackPage from '@/components/BackPage.vue'

@Options({
  components: {
    Layout,
    Page,
    BackPage
  },
  beforeMount() {
    document.body.classList.add('app-bg-img')
  },
  beforeUnmount() {
    document.body.classList.remove('app-bg-img')
  }
})
export default class SettingsView extends Vue {}
