
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'
import Separator from '@/components/Separator.vue';
import Button from '@/components/auth/Button.vue'
import SpinnerIcon from '@/components/icons/Spinner.vue'

@Options({
     props: {
          showSheet: Boolean,
          title: String||null,
     },
     components: {
          Sheet,
          Separator,
          Button,
          SpinnerIcon
     },
})
export default class GifSheetComponent extends Vue {
     showSheet!: boolean;
     title!: string|null;
}
