
import { Options, Vue } from 'vue-class-component'
import Button from '@/components/auth/Button.vue'
import LoginIcon from '@/components/icons/Login.vue'
// icons
import UserIcon from '@/components/icons/User.vue'
import LockIcon from '@/components/icons/Lock.vue'
import EmailIcon from '@/components/icons/Email.vue'
import ArrowRightIcon from '@/components/icons/ArrowRight.vue'
@Options({
     data(){
          return {
               processing: false,
               formFields: {},
               errors: {},
               message: '',
               current_field: '',
               showSubmitButton: false,
          }
     },
     components: {
          Button,
          LoginIcon,
          //FormInput,
          UserIcon,
          LockIcon,
          EmailIcon,
          ArrowRightIcon
     },
     props: {
          btnTitle: String,
          fields: Array,
          hasSlotBeforeSubmitButton: Boolean,
          formApi: String,
     },
     methods: {
          async startAction() {
               if(this.hasNext) return this.nextField()
               this.errors = {}
               this.message = ''
               this.processing = true;
               let res
               try {
                    res = await this.axios.post(this.formApi, this.formFields)
               } catch(e: any) {
                    res = e.response
               }
               if(res.status !== 200) {
                    let unknown = true
                    this.current_field = this.fields[0]
                    if(res.data.message) {
                         this.$toast.error(res.data.message)
                         unknown = false
                    }
                    if(res.data.errors) {
                         this.errors = res.data.errors
                         unknown = false
                    } 
                    if(unknown) this.$toast.error(this.$t('error.unknown'))
                    this.processing = false;
                    return
               }
               this.processing = false;
               this.$emit('success', res.data)
          },
          async nextField() {
               const index = this.fields.indexOf(this.current_field)
               this.current_field = this.fields[index+1]
               await this.$sleep(200)
          }
     },
     beforeMount() {
          this.current_field = this.fields[0]
     },
     computed: {
          hasNext() {
               return this.fields[this.fields.length-1].name !== this.current_field.name
          }
     },
})

export default class AuthFormComponent extends Vue {
     btnTitle!: string;
     fields!: Array<ArrayBuffer>;
     hasSlotBeforeSubmitButton!: boolean;
     formApi!: string;
}
