<template>
  <router-view v-slot="{ Component, route }">
    <transition name="custom-fade" mode="out-in" appear>
      <div :key="route.name">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
</template>
<script setup lang="ts">
</script>


<style>
.custom-fade-enter-active {
  transition: all 0.3s ease-out;
}
.custom-fade-enter-from,
.custom-fade-leave-to {
  opacity: 0;
}
</style>