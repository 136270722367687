
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/auth/Button.vue'
import StarIcon from '@/components/icons/Star.vue'
import LinkButton from "@/components/mobile/LinkButton.vue";
import AnswerButton from "@/components/home/AnswerButton.vue";
import UserProfile from "@/components/home/UserProfile.vue";
import Description from "@/components/home/post/Description.vue";
import MusicPlayer from "@/components/home/post/MusicPlayer.vue";

@Options({
  components: {
    MusicPlayer,
    Description,
    UserProfile,
    AnswerButton,
    LinkButton,
    Button,
    StarIcon
  },
  props: {
    post: Object||null,
    checkButton: Boolean,
  },
})
export default class HomePageQuestionView extends Vue {
  post!: object|null;
  checkButton!: boolean;
}
