export default {
    // Errors
    'error.unknown': 'An unknown error occurred',
    'subscription2': 'subscription',

    //
    'Max {0} letter': 'Max {0} letter',
    'Min {0} letter': 'Min {0} letter',

    // Languages
    'en.fullname': 'English',
    'hu.fullname': 'Hungarian',
}