import { setBackgroundColor } from "./mobile/statusbar";
import isApp from "@/ts/mobile/isApp";
import __dev_log from "@/ts/__dev_log";
const isDev = process.env.NODE_ENV === "development";

class ThemeManager {
     #theme = 'light';
     #shades = {
          'light': [
               '#ffffff',
               '#ffffff',
               '#f9fafb'
          ],
          'dark': [
               '#111827',
               '#1f2937',
               '#1f2937',
          ]
     }
     #navShades = {
          'light': [
               '#ffffff',
               '#ffffff',
               '#f9fafb',
          ],
          'dark': [
               '#111827',
               '#1f2937',
               '#1f2937',
          ]
     }
     #currentShade = 0
     #currentShadeBottom = 0

     constructor() {
          const theme = window.localStorage.getItem('theme') || 'light'
          this.checkAndSet(theme)
     }

     setShade(i: number): void {
          this.#currentShade = i
          if(isDev) console.info('[ThemeManager] Shade changed to:', i)
     }

     navbarColor(shade: number) {
          let _ = this.#navShades.light
          if(this.#theme == 'dark') _ = this.#navShades.dark
          this.#currentShadeBottom = shade
          if(isApp()) NavigationBar.backgroundColorByHexString(_[shade], this.#theme == 'light');
     }

     checkAndSet(theme: string) {
          if(['light','dark'].includes(theme)) {
               this.#theme = theme
          } else this.#theme = 'light'

          this.navbarColor(this.#currentShadeBottom)

          window.localStorage.setItem('theme', this.#theme)
          const html = document.querySelector('html')
          if(html?.classList.contains('light') || html?.classList.contains('dark')) {
               html.classList.remove('light')
               html.classList.remove('dark')
               html.classList.add(this.#theme)
          }

          this.setTop()
          this.logColorSet()
     }

     setTop(): void {
          if(this.#theme === 'light') {
               setBackgroundColor(this.#shades.light[this.#currentShade])
          } else {
               setBackgroundColor(this.#shades.dark[this.#currentShade])
          }
          if(isDev) console.info(`[ThemeManager]:${this.#theme} Top setted to: ${this.#currentShade}`)
     }

     getTheme() {
          return this.#theme
     }

     setTheme(theme: string) {
          this.checkAndSet(theme)
     }

     isLight() {
          return this.#theme === 'light'
     }

     logColorSet() {
          let shadeTheme = this.#shades.dark
          if(this.isLight()) shadeTheme = this.#shades.light
          const shadeColorCode = shadeTheme[this.#currentShade]
          let styles: Array<string> = [
               `color: ${this.isLight() ? '#000000' : '#ffffff'}`,
               'font-size: 40px',
               'font-weight: 600',
               'padding: 25px 10px',
               'background-color: ' + shadeColorCode
          ]
          __dev_log.log(`%cTop Setted to: ${this.#currentShade}, code: [${shadeColorCode}]`, styles.join(';'));

          let shadeThemeBottom = this.#navShades.dark
          if(this.isLight()) shadeThemeBottom = this.#navShades.light

          const shadeColorCodeBottom = shadeThemeBottom[this.#currentShadeBottom]

          styles = [
               `color: ${this.isLight() ? '#000000' : '#ffffff'}`,
               'font-size: 40px',
               'font-weight: 600',
               'padding: 25px 10px',
               'background-color: ' + shadeColorCodeBottom
          ]

          __dev_log.log(`%cBottom Setted to: ${this.#currentShadeBottom}, code: [${shadeColorCodeBottom}]`, styles.join(';'));
     }

     save() {
          this.checkAndSet(this.getTheme())
     }
}

export default new ThemeManager()