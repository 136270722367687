
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: String
  }
})

export default class HrWithTextComponent extends Vue {
     title!: string;
}
