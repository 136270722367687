
import {Options, Vue} from "vue-class-component";
import Spinner from "@/components/icons/Spinner.vue";
import Premium from "@/components/icons/Premium.vue";
import Button from "@/components/auth/Button.vue";
import LinkButton from "@/components/mobile/LinkButton.vue";

@Options({
  components: {LinkButton, Button, Premium, Spinner},
  data() {
    return {
      loaded: false,
      users: []
    }
  },
  props: {
    username: String,
  },
  methods: {
    premiumCubeImage(user: any) {
      return(
          user.is_premium && user.premium_settings &&
          user.premium_settings.cube_profile_image
      )
    }
  },
  async mounted() {
    let res
    try {
      res = await this.axios.post('/user/recommend_like', {
        username: this.username
      })
    } catch(e: any) {
      console.error(e.message)
      this.$toast.error('Failed to find recommended users')
      return
    }
    this.users = res.data.users
    this.loaded = true
  }
})
export default class extends Vue {}
