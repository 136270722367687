import { createI18n } from 'vue-i18n'
import en from './data/en';
import hu from './data/hu'
import store from "@/store";

const i18n = createI18n({
    legacy: false,
    locale: store.getters['settings/getLanguage'],
    fallbackLocale: 'en',
    messages: {
        en,
        hu,
    },
    fallbackWarn: false,
    missingWarn: false
})

export default i18n