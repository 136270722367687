import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center cursor-pointer" }
const _hoisted_2 = { class: "appFont transition text-black dark:text-white duration-150 min-w-[55px] min-h-[55px] w-[55px] h-[55px] bg-white hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full flex items-center justify-center text-xl mx-auto" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ]))
}