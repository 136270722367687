import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen app-bg-img" }
const _hoisted_2 = { class: "flex h-screen overflow-hidden" }
const _hoisted_3 = { class: "m-auto z-10 px-2 sm:px-0 w-auto text-center" }
const _hoisted_4 = { class: "text-2xl mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorIcon = _resolveComponent("ErrorIcon")!
  const _component_Server = _resolveComponent("Server")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BrowserLink = _resolveComponent("BrowserLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.server_error)
          ? (_openBlock(), _createBlock(_component_ErrorIcon, {
              key: 0,
              class: "fa-5x text-red-500 dark:text-red-400"
            }))
          : (_openBlock(), _createBlock(_component_Server, {
              key: 1,
              class: "fa-5x text-red-500 dark:text-red-400"
            })),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.message) || _ctx.$t('error.unknown')), 1),
        _createVNode(_component_Button, {
          onClick: _ctx.retry,
          grayTheme: false,
          class: "mt-5 shadow"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Retry')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.help_url)
          ? (_openBlock(), _createBlock(_component_BrowserLink, {
              key: 2,
              href: _ctx.help_url,
              class: "w-full mt-3 block"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, { "gray-theme": true }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Help')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["href"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}