
import { Options, Vue } from 'vue-class-component'
import AppLayout from '@/components/layouts/AppLayout.vue';
import Separator from '@/components/Separator.vue';
import TextInput from './TextInput.vue'
import Button from './Button.vue'
import ButtonArrowLeftIcon from '@/components/icons/ButtonArrowLeft.vue'
import ButtonArrowRightIcon from '@/components/icons/ButtonArrowRight.vue'
import 'vue-datepicker-next/index.css'
import DatePicker from 'vue-datepicker-next';
import Select from './Select.vue'
import Interests from './Interests.vue'
import SpinnerIcon from '@/components/icons/Spinner.vue'
import loadUp from '@/ts/app/loadUp';

@Options({
     data() {
          return {
               models: {},
               errors: {},
               ps: [],
               current: 0,
               processing: false,
          }
     },
     components: {
          AppLayout,
          Separator,
          TextInput,
          Button,
          ButtonArrowLeftIcon,
          ButtonArrowRightIcon,
          DatePicker,
          Select,
          Interests,
          SpinnerIcon,
     },
     props: {
          dataset: Array,
     },
     computed: {
          currentSelectionName() {
               return Object.keys(this.ps)[this.current]
          },
          currentSelection() {
               return this.ps[Object.keys(this.ps)[this.current]]
          },
          hasPrev() {
               return this.current > 0
          },
          hasNext() {
               return this.current+1 < Object.keys(this.ps).length
          },
     },
     methods: {
          swipe(direction: boolean) {
               if(direction) this.current++
               else this.current--
          },
          async submit() {
               this.errors = {}
               this.processing = true
               let res
               try {
                    res = await this.axios.post('/profile-setup-state', this.models)
               } catch(e: any){
                    console.error(e.message)
                    res = e.response
               }
               if(res.status == 200) {
                    await loadUp()
               } else {
                    if(res.data.message) this.$toast.error(res.data.message)
                    if(res.data.errors) this.errors = res.data.errors
                    this.current = 0
               }
               this.processing = false
          }
     },
     beforeMount() {
          // Object.keys(this.dataset).forEach(key => {
          //      if(this.dataset[key].require == true) this.ps[key] = this.dataset[key]
          // })
          const filtered = Object.entries(this.dataset).filter(([key, value]) => key == 'require' || value)
          this.ps = Object.fromEntries(filtered)
          const _models = filtered.map(([key]) => [key, ''])
          this.models = Object.fromEntries(_models)
     }
})
export default class ProfileSetupComponent extends Vue {
     dataset!: Array<any>;
}
