
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'
import IconCirlce from '@/components/utils/IconCircle.vue'
import MoreDotsIcon from '@/components/icons/MoreDots.vue'
import DownloadIcon from '@/components/icons/Download.vue'

@Options({
     data() {
          return {
               shareSheet: false
          }
     },
     components: {
          Sheet,
          IconCirlce,
          MoreDotsIcon,
          DownloadIcon
     },
     props: {
          shareSheet: Boolean,
     }
})
export default class ShareSheetComponent extends Vue {
     shareSheet!: boolean;
}
