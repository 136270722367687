import instance from "./initAxios";
import {useToast} from "vue-toastification";

const axiosHE = instance

axiosHE.interceptors.response.use(
    (response: any) => {
        return response
    },
    (e: any) => {
        console.error('ErrorHandledAxiosErrorConsoleOut',e.message)
        if(e.response.data?.message) {
            useToast().error(e.response.data.message)
        }
        return e.response
    }
)

export default axiosHE