export type State = {
     next_upload_at: string|number|null;
}

const state: State = {
     next_upload_at: null,
}

const getters = {
     canUpload: (state: State): boolean => {
          return !!state.next_upload_at && ((new Date(state.next_upload_at)).getTime() <= (new Date()).getTime())
     },
}

const actions = {

}

const mutations = {
     setNext(state: State, payload: number) {
          state.next_upload_at = payload
     },
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}