
import { Options, Vue } from 'vue-class-component'
import LinkButton from '@/components/mobile/LinkButton.vue'

@Options({
     components: {
          LinkButton
     },
     props: {
          title: String || null,
     }
})

export default class NavLink extends Vue {
     title!: string|null;
}
