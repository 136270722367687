import store from "@/store";
import axios from '@/ts/initAxios'
import theme from "../theme";
import {useToast} from "vue-toastification";
import __dev_log from "@/ts/__dev_log";

const serverLoadSettings = async () => {
     let res
     try {
          res = await axios.get('/settings')
     } catch(e: unknown) {
          console.error('Unable to fetch settings')
     }

     if(res?.status == 200 && res.data.settings) {
          const settings = res.data.settings
          theme.checkAndSet(settings.dark_mode ? 'dark' : 'light')
          store.commit('settings/setVibrationStyle', {
               enabled: settings.vibration,
               style: settings.vibration_level
          })
          store.commit('settings/setPushNotificationEnabled', settings.push_notifications || false)
          const locale = settings.language || navigator.language || 'en'
          store.commit('settings/setLanguage', locale)
     } else {
          __dev_log.log(navigator.language || 'en')
          store.commit('settings/setLanguage', navigator.language || 'en')
          useToast().error('Failed to fetch your custom settings')
     }
     await nextPostTime()
}

export const nextPostTime = async () => {
     let res
     try {
          res = await axios.get('/upload/can')
     } catch(e: unknown) {
          console.error('Unable to fetch settings')
     }
     if(res?.status == 200 && res?.data?.next_upload_at) {
          store.commit('postTimer/setNext', res.data.next_upload_at)
     }
}

export default serverLoadSettings