
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'
import Switch from '@/components/utils/Switch.vue'
import Separator from '@/components/Separator.vue';
import vibrate from '@/ts/mobile/vibrate'
import { mapGetters } from 'vuex'

@Options({
     computed: {
          ...mapGetters({
               enabled: 'settings/allowVibration',
               selected: 'settings/getVibrationStyle'
          })
     },
     data() {
          return {
               levels: ['Light', 'Medium', 'Heavy'],
               canSelect: true,
          }
     },
     components: {
          Sheet,
          Switch,
          Separator
     },
     methods: {
          async setLevel(level: string) {
               this.canSelect = false
               this.selected = level
               await vibrate(level, 500);
               this.canSelect = true
               this.commit(level, this.enabled)
          },
          changeEnable() {
               this.commit(this.selected, !this.enabled)
          },
          commit(style: string, enabled: boolean) {
               this.$store.commit('settings/setVibrationStyle', {
                    style,
                    enabled
               })
               this.$emit('onSync', {
                    vibration: enabled,
                    vibration_level: style.toLowerCase()
               })
          }
     },
     props: {
          showSheet: Boolean,
     }
})
export default class VibrationSheetComponent extends Vue {
     showSheet!: boolean;
}
