import { Preferences } from '@capacitor/preferences';

class StorageDB {
    async set(key: string, value: any) {
        await Preferences.set({
            key: key,
            value: JSON.stringify(value)
        });
    }

    async get(key: string): Promise<any> {
        const ret = await Preferences.get({ key: key });
        return JSON.parse(ret.value) || null;
    }
}

export default new StorageDB()