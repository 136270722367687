
import { Options, Vue } from 'vue-class-component';
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import Form from '@/components/auth/Form.vue'
import InAppLink from '@/components/utils/InAppLink.vue'
import { API_ROUTES } from '@/ts/apiRoutes'

// loadup
import loadUp from '@/ts/app/loadUp'

@Options({
  setup() {
    const formRoute = API_ROUTES.auth.register;
    return { formRoute }
  },
  data(){
    return {
      fields: [
        {
          placeholder: this.$t('Username'),
          icon: 'user',
          type: 'text',
          name: 'username',
          title: this.$t('Choose a username that reflects you') + ' ⭐',
        },
        {
          placeholder: 'Email',
          icon: 'email',
          type: 'email',
          name: 'email',
          title: this.$t('Enter an email address'),
        },
        {
          placeholder: this.$t('Password'),
          icon: 'lock',
          type: 'password',
          name: 'password',
          title: this.$t('For your own good, choose a strong password') + ' 🔒'
        }
      ]
    }
  },
  components: {
    AuthLayout,
    Form,
    InAppLink
  },
  methods: {
    async authUser(data: any) {
      if(data.user) {
        this.$store.commit('auth/setUserLoggedIn', data.user);
        await loadUp()
        this.$router.push('/');
      } else this.$toast.error(this.$t('error.unknown'))
    }
  }
})
export default class SignUpView extends Vue {}
