const API_ROUTES = {
     baseURL: window.config.server.API,
     auth: {
          login: '/login',
          register: '/register',
          forgotPassword: '/forgot-password',
     }
}

const WS_ROUTES = {

}

export {
     API_ROUTES,
     WS_ROUTES,
};