import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isApp())
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        role: "button",
        target: "_blank",
        href: _ctx.href
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.browser && _ctx.browser(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}