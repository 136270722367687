import { createApp } from 'vue'
import App from './App.vue'

// Files
import './css/index.css'
import '@/css/toast.css'
import 'tippy.js/dist/tippy.css'
import 'bottom-sheet-vue3/style.css'
//import 'aos/dist/aos.css'
// Usings
import addUsings from './ts/main/usings'
import addGlobals from './ts/main/globals'
import addComponents from "@/ts/main/components";
import './ts/main/needs'

const app = createApp(App)

addUsings(app)
addGlobals(app)
addComponents(app)

app.mount('#app')
