
import { Options, Vue } from 'vue-class-component';
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Page from '@/components/home/Page.vue'
import Posts from '@/components/home/Posts.vue'

@Options({
  data() {
    return {
      data: [],
      allLoaded: false,
      failedMore: false,
      usedMount: false,
    }
  },
  methods: {
    async more() {
      const cursor = this.$store.getters['posts/cursor']
      if(!cursor) {
        this.allLoaded = true
        return
      }
      const res = await this.load(cursor)
      if(res === false) this.failedMore = false
      else {
        this.$store.commit('posts/setData', res)
        this.data = this.$store.getters['posts/data']
      }
    },
    async load(cursor: string) {
      let res, error
      try {
        res = await this.axios.get(`/home?${cursor ? 'cursor='+cursor : ''}`)
      } catch (e: any) {
        console.error(e.message)
        error = true
      }
      if(error || res.status !== 200) return false
      else return res.data      
    }
  },
  async beforeMount() {
    const posts = this.$store.getters['posts/data']
    if(posts.length > 0) {
      this.data = posts
      return
    }
    if(!this.usedMount) {
      this.usedMount = true
    } else return
    const res = await this.load()
    if(res === false) {
      this.$store.commit('loadScreen/setScreen', {
        isLoading: false,
        hasError: true,
        errorMessage: this.$t('Failed to fetch posts from the API'),
      })
    } else {
      this.$store.commit('posts/setData', res)
      this.data = this.$store.getters['posts/data']
    }
  },
  components: {
    Layout,
    Page,
    Posts
  },
})
export default class HomeView extends Vue {}
