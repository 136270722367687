
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/auth/Button.vue'
import SettingsIcon from '@/components/icons/Settings.vue'
import Switch from '@/components/utils/Switch.vue'
import soundEffects from '@/ts/sound/soundEffects'

@Options({
     data() {
          return {
               soundEffects: soundEffects,
               isOpen: false,
               isDark: false
          }
     },
     components: {
          Button,
          SettingsIcon,
          Switch
     },
     methods: {
          switchTheme() {
               this.isDark = !this.isDark
               this.$theme.setTheme(this.isDark ? 'dark' : 'light')
          },
     },
     beforeMount() {
          this.isDark = this.$theme.getTheme() === 'dark'
     }
})
export default class AuthSettingsButtonComponent extends Vue {}
