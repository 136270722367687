import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNav = _resolveComponent("TopNav")!
  const _component_AppLayout = _resolveComponent("AppLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.disableTopNav)
      ? (_openBlock(), _createBlock(_component_TopNav, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AppLayout, {
      classList: _ctx.classList,
      removePadding: _ctx.removePadding
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["classList", "removePadding"])
  ], 64))
}