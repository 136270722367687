import { Keyboard } from '@capacitor/keyboard';
import store from '@/store';
import isApp from "@/ts/mobile/isApp";


export default function () {
     if(!isApp()) return;

     Keyboard.addListener('keyboardWillShow', (info) => {
          store.commit('appearence/setkeyboardOpen', {
               open: true,
               height: info.keyboardHeight
          })
     });
     
     Keyboard.addListener('keyboardDidShow', (info) => {
          store.commit('appearence/setkeyboardOpen', {
               open: true,
               height: info.keyboardHeight
          })
     });

     Keyboard.addListener('keyboardWillHide', () => {
          store.commit('appearence/setkeyboardOpen', {
               open: false,
          })
     });
     
     Keyboard.addListener('keyboardDidHide', () => {
          store.commit('appearence/setkeyboardOpen', {
               open: false,
          })
     });
}