import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 grid-rows-2 gap-2" }
const _hoisted_2 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tippy = _resolveComponent("Tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content) => {
      return (_openBlock(), _createBlock(_component_Tippy, {
        key: content.key,
        content: content.name + (content.premium ? ' (' + _ctx.$t('Premium is required') + ')' : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.select(content)),
            class: _normalizeClass(["cursor-pointer w-full px-5 h-[80px] md:h-[150px] bg-gray-300 dark:bg-gray-900 border-gray-300 dark:border-gray-900 rounded-lg flex items-center justify-center border-2 disabled:opacity-50", {
            'animate-pulse': _ctx.selected != content.key,
            '!border-primary-300': _ctx.selected == content.key,
            '!cursor-not-allowed': content.premium && !_ctx.user().is_premium
          }]),
            disabled: content.premium && !_ctx.user().is_premium
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(content.icon), {
              class: _normalizeClass(["fa-2x text-gray-100 dark:text-gray-600", {'text-primary-400 dark:text-primary-300': content.premium}])
            }, null, 8, ["class"]))
          ], 10, _hoisted_2)
        ]),
        _: 2
      }, 1032, ["content"]))
    }), 128))
  ]))
}