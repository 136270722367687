import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-xl pt-4 appFont" }
const _hoisted_2 = { class: "flex items-center space-x-3 w-fit mx-auto" }
const _hoisted_3 = { class: "pt-2 text-center" }
const _hoisted_4 = { class: "my-3" }
const _hoisted_5 = { class: "grid grid-cols-3 gap-3 w-fit mx-auto" }
const _hoisted_6 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createBlock(_component_Sheet, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    addHeader: true,
    visibility: _ctx.showSheet,
    addPadX: true
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('Vibrations')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Separator),
      _createElementVNode("main", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Enable vibration')), 1),
          _createVNode(_component_Switch, {
            checked: _ctx.enabled,
            onChange: _ctx.changeEnable
          }, null, 8, ["checked", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('Select a level')), 1),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (level) => {
              return (_openBlock(), _createBlock(_component_Tippy, {
                key: level,
                content: level
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.setLevel(level)),
                    disabled: !_ctx.canSelect || !_ctx.enabled,
                    class: _normalizeClass(["appFont transition text-black dark:text-white duration-150 w-16 h-16 md:w-20 md:h-20 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 rounded-lg p-1 flex items-center justify-center text-xl mx-auto disabled:bg-gray-500 dark:disabled:bg-gray-500", {'text-white bg-primary-400 dark:bg-primary-400 hover:bg-primary-300 dark:hover:bg-primary-500':level == _ctx.selected}])
                  }, _toDisplayString(level.split('')[0]), 11, _hoisted_6)
                ]),
                _: 2
              }, 1032, ["content"]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visibility"]))
}