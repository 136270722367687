import refresh from "./refreshToken";
import db from "@/ts/db";

const tokenSet = async (token: string, type: string): Promise<void> => {
     const jwt = {
          token, 
          type,
          created_at: new Date().getTime()
     };
     await db.set('token', jwt);
}

const tokenGet = async (): Promise<any|null> => {
     const jwt = await db.get('token');
     if(jwt){
          if(jwt.type && jwt.token) {
               const now = new Date()
               now.setDate(now.getDate() - 3)
               if(jwt.date > now) await refresh(jwt)
               return {
                    token: `${jwt.type} ${jwt.token}`,
                    date: jwt.created_at
               };
          }
     }
     return null;
}

export {
     tokenGet,
     tokenSet
}