export type SizePayload = {
     open: boolean;
     height: number|null;
}

export type State = {
     bottomNavSize: number,
     keyboardOpen: boolean,
     keyboardHeight: number,
}

const state: State = {
     bottomNavSize: 0,
     keyboardOpen: false,
     keyboardHeight: 0,
}

const getters = {
     bottomNavSize: (state: State): number => state.bottomNavSize,
     keyboardOpen: (state: State): boolean => state.keyboardOpen,
     keyboardHeight: (state: State): number => state.keyboardHeight,
}

const actions = {
     
}

const mutations = {
     setbottomNavSize(state: State, size: number|null): void {
          state.bottomNavSize = size || 0
     },
     setkeyboardOpen(state: State, payload: SizePayload): void {
          state.keyboardOpen = payload.open
          if(payload.height) state.keyboardHeight = payload.height
     },
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}