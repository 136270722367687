import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "group transition duration-150 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 p-3" }
const _hoisted_3 = { class: "flex items-center w-full" }
const _hoisted_4 = { class: "ml-3 mr-auto" }
const _hoisted_5 = {
  key: 0,
  class: "transition duration-150 ml-auto text-gray-500"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "transition duration-150 text-sm text-gray-600 text-left mt-2 group-hover:text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowRight = _resolveComponent("ArrowRight")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "text-gray-500 text-xl" }))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]),
        (!_ctx.useSlot)
          ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
              _createVNode(_component_ArrowRight)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default")
            ]))
      ]),
      (_ctx.desc)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.desc), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}