export default {
    // Authentication resource
    "Are you ready for a brand new community?": "Készen állsz egy vadonatúj közösségre?",
    "Forgot Password": "Elfelejtett jelszó",
    "We will email you a password reset url ;)": "E-mailben küldünk neked egy jelszó-visszaállítási url-t ;)",
    "In case of any problems please": "Bármilyen probléma esetén kérjük",
    "click here": "kattintson ide",
    "We are happy to have you back": "Örülünk, hogy újra itt vagy",
    "Sign in": "Bejelentkezés",
    "Forgot password?": "Elfelejtette jelszavát?",
    "We hope to see you again": "Reméljük, hogy újra találkozunk",
    "Sign up": "Regisztráció",
    "By signing up you agree to our": "A regisztrációval elfogadja a",
    "Terms & conditions": "Felhasználási feltételeket",
    and: "és",
    "Privacy Policy": "az Adatvédelmi irányelveket",
    "Username or email": "Felhasználónév vagy email cím",
    "Enter your username or email": "Add meg felhasználóneved vagy email címed",
    Password: "Jelszó",
    "We're almost done": "Már majdnem kész vagyunk",
    Username: "Felhasználónév",
    "Choose a username that reflects you": "Válassz egy hozzád illő felhasználónevet",
    "Enter an email address": "Adjon meg egy email címet",
    "For your own good, choose a strong password": "A saját érdekedben válassz egy erős jelszót",
    // Hiba oldalak
    "Oops! It seems this page does not exist or has been deleted.": "Hoppá! Úgy tűnik, ez az oldal nem létezik, vagy törölték.",
    // Pages
    "Failed to fetch posts from the API": "Nem sikerült lekérni a bejegyzéseket az API-ból",
    "Bio Links": "Bio linkek",
    "Edit profile image": "Profilkép szerkesztése",
    "Edit account": "Fiók szerkesztése",
    Premium: "Prémium",
    subscription: 'előfizetés',
    "Support our development team and unlock a": "Támogasd a fejlesztő csapatunkat és oldd fel a",
    "lot of": "sok",
    "cool": "menő",
    "feature": "funkciót",
    "Just cool, there's nothing more to say":"Csak menő, nincs több mondanivaló",
    "What do I get when I subscribe?": "Mit kapok, ha előfizetek?",
    "No answer found, be the first": "Nincs válasz, légy te az első",
    "Just a moment": "Egy pillanat",
    "Settings and privacy": "Beállítások és adatvédelem",
    // Users
    "Loading": "Betöltés",
    "Your public view": "A profilja nyilvános nézete",
    // Components
    // app
    "Select topics": "Válasszon témákat",
    "Hi! Set up your profile to continue": "Szia! Állítsd be a profilod a folytatáshoz",
    "Back": "Vissza",
    "Next": "Következő",
    "Submit": "Küldés",
    "Please select": "Kérlek válassz",
    // auth
    "OK? Click here": "Rendben? Kattintson ide",
    // home
    "Report": "Bejelentés",
    "View Profile": "Profil megtekintése",
    "Follow": "Követés",
    "Unfollow": "Követés megszüntetése",
    "Requested": "Kérelmezve",
    "Successfully copied to clipboard": "Sikeresen a vágólapra másolva",
    "An unknown error occurred, maybe check your permissions": "Ismeretlen hiba történt, ellenőrizze a jogosultságokat",
    "See more": "Továbbiak",
    "Take a break, that's all for now": "Tarts egy kis szünetet, egyelőre ennyi",
    "Failed to load more": "Nem sikerült betölteni többet",
    "Check out": "Nézd meg",
    "premium": "prémium",
    "subscription2": "feliratkozással",
    "Support us via": "Támogasson minket",
    "Relax, it's good for you too, there will be more and more new features for premium subscribers": "Nyugalom, ez neked is jó, egyre több új funkció lesz a prémium előfizetők számára",
    "More information": "További információk",
    // layouts
    "Register": "Regisztráció",
    "Sign in with": "Bejelentkezés",
    "Google": "Google-al",
    // me
    "Questions": "Kérdések",
    'Posts': 'Posztok',
    "Answers": "Válaszok",
    "Add":"Hozzáadás",
    "Cancel": "Mégse",
    "Meta Preview": "Meta előnézete",
    "No WiZZL meta tags found": "Nem találtunk WiZZL meta tageket",
    "Check the documentation": "Nézze meg a dokumentációt",
    "Request successfully sent, it takes time to make the actual change": "A kérelem sikeresen elküldve, a tényleges módosításhoz időre van szükség",
    "Successfully deleted": "Sikeresen törölve",
    "Update profile picture": "Profilkép frissítése",
    "General": "Általános",
    "Save": "Mentés",
    "Change Bio Links": "Bio linkek módosítása",
    "Profile Settings": "Profilbeállítások",
    "Progress": "Teljesítmény",
    "Activity": "Aktivitás",
    "Successfully updated": "Sikeresen frissítve",
    "Select the shape of your profile image": "Válassza ki a profilkép alakját",
    "Username colors": "Felhasználónév színek",
    "Light mode": "Világos üzemmód",
    "Dark mode": "Sötét üzemmód",
    "Current": "Jelenlegi",
    "Preview": "Előnézet",
    "Note": "Megjegyzés",
    "Don't worry, your preferences will be kept permanently if your premium expires, and they will be applied when you subscribe again": "Ne aggódj, a preferenciáidat megőrizzük, ha a prémiumod lejár, és alkalmazzuk, amikor újra feliratkozol",
    "Reset": "Visszaállítás",
    "Upload": "Feltöltés",
    'Something went wrong on the CDN API': "Valami elromlott a CDN API-nál",
    "Failed to verify image upload": "Nem sikerült hitelesíteni a képfeltöltést",
    "Current streak": "Jelenlegi Kérdéssorozat",
    "Point Multiplier": "Pont Szorzó",
    // navs
    "Text decorations": "Szöveges díszítések",
    "Search for user to tag them": "Felhasználó keresése, hogy megjelölje őket",
    "Home": "Főoldal",
    "Chats": "Chatek",
    "My Profile": "Profilom",
    "Please be more specific!": "Kérlek, légy pontosabb!",
    // premium
    "Want to be more unique?": "Szeretne még egyedibb lenni?",
    "Custom username color, animated profile picture, premium subscriber badge, shall I list more?": "Egyedi felhasználónév szín, animált profilkép, prémium előfizetői jelvény, soroljam még?",
    "Are you READY?": "KÉSZEN Állsz?",
    "Choose the one that suits you best": "Válaszd a neked legmegfelelőbbet",
    "Most popular": "Legnépszerűbb",
    "money back guaranteed until the first week of use": "pénzvisszafizetési garancia a használat első hetéig",
    "Subscribe": "Feliratkozás",
    "What is this good for?": "Mire jó ez?",
    "Benefits": "Előnyök",
    "Monthly": "Havonta",
    "Every 3 months": "Három havonta",
    "Annually": "Évente",
    "Why is it worth subscribing?": "Miért éri meg feliratkozni?",
    "Show more": "Mutass többet",
    "Show less": "Mutass kevesebbet",
    // settings
    'Common': 'Általános',
    'Not implemented': "Nincs megvalósítva",
    "Edit profile": "Profil szerkesztése",
    "Change your username, update your profile picture, etc.": "Módosítsa felhasználónevét, frissítse profilképét stb.",
    "Private account": "Privát fiók",
    "Once this is turned on, no one can just follow you, you will have to accept tracking requests, and only then will they have access to the content you post and your contact details": "Ha ez be van kapcsolva, senki sem tud csak úgy követni téged, el kell fogadnod a követési kéréseket, és csak ezután férhetnek hozzá az általad közzétett tartalmakhoz és az elérhetőségeidhez",
    "Account suspension and deletion": "Fiók felfüggesztése és törlése",
    "Suspension": "Felfüggesztés",
    "All your account information will be private and will not appear anywhere until you log in again (this may take several hours and you will be logged out of all your devices)": "Minden fiókadata privát lesz, és nem jelenik meg sehol, amíg újra be nem jelentkezik (ez több órát is igénybe vehet, és minden eszközéről ki lesz jelentkezve)",
    "Delete account": "Fiók törlése",
    "Sessions and tokens": "Munkamenetek és tokenek",
    "Manage sessions": "Bejelentkezések kezelése",
    "IP Auth": "IP Hitelesítés",
    "Access to your account using only IP addresses that are already permitted (turning this off may increase the risk of the profile being hacked)": "Hozzáférés a fiókjához csak a már engedélyezett IP-címek használatával (ennek kikapcsolása növelheti a profil feltörésének kockázatát)",
    "Fonts": "Betűtípusok",
    "Vibrations": "Vibrációk",
    "Enable vibration": "Vibrációk engedélyezése",
    "Select a level": "Válasszon ki egy szintet",
    "Account": "Fiók",
    "Safety": "Biztonság",
    "Logout": "Kijelentkezés",
    "Appearance": "Megjelenés",
    "Theme": "Téma",
    "Blurry navigation bar": "Áttetszős navigációs sáv",
    "Mobile": "Mobil",
    "Push notifications": "Push értesítések",
    "Developers": "Fejlesztők",
    "Public API": "Publikus API",
    "Help & Support": "Súgó és támogatás",
    "Support": "Súgó",
    "About us": "Rólunk",
    "Our team": "Csapatunk",
    "Donate": "Támogatás",
    'Language': 'Nyelv',
    'Select': 'Válassz',

    // Upload
    'New Post': 'Új bejegyzés',
    'Title': 'Cím',

    //
    "Anonymous": "Ismeretlen",
    'Max {0} letter': 'Max {0} betű',
    'Min {0} letter': 'Min {0} betű',
    'letter': 'betű',
    'Description': 'Leírás',
    'How to write a good description?': 'Hogyan írj egy jó leírást?',
    'Post': 'Közzététel',
    'Data traffic': 'Adatforgalom',
    'Network': 'Hálózat',
    'Data-saving mode': 'Adattakarékos mód',
    'No Wi-Fi? Maybe you have limited data left? Don\'t worry, turning it on will reduce your data traffic, but you\'ll get poorer quality content in return': 'Nincs Wi-Fi? Esetleg csak korlátozott adatforgalmad maradt? Ne aggódj, ennek bekapcsolása csökkenti az adatforgalmat, de cserébe rosszabb minőségben láthatsz tartalmakat.',
    'Switching off in case of wifi': 'Wifi esetén automatikus kikapcsolás',

    // Hibaüzenetek
    'error.unknown': 'Ismeretlen hiba történt',
    'Our servers could not be reached': 'Nem sikerült kapcsolódni a szervereinkhez',
    'Retry': 'Újra',
    'Failed to load image': 'Nem sikerült betölteni a képet',

    'Hey! You\'ll have to wait': 'Héj! Még várnod kell',
    'Choose': 'Kiválaszt',
    'Title of music': 'A zene címe',
    'The song you are looking for may be copyrighted and therefore not available': 'Előfordulhat hogy a keresett zene szerzői jogok alatt áll, ezért nem található',

    'Music': 'Zene',
    'Image': 'Kép',
    'Voice recording': 'Hangfelvétel',
    'Premium is required': 'Prémium előfizetés szükséges',

    'Options': 'Opciók',

    // Nyelvek
    'en.fullname': 'Angol',
    'hu.fullname': 'Magyar',
}