
import { Options, Vue } from 'vue-class-component';
import Spinner from "@/components/icons/Spinner.vue";
import AnswerSheet from "@/components/home/sheets/AnswerSheet.vue";

@Options({
  data() {
    return {
      loading: false,
      showSheet: false,
    }
  },
  components: {AnswerSheet, Spinner},
  props: {
    post_id: Number,
  },
  methods: {
    loadAnswerModal() {
      this.loading = true
      this.showSheet = true
    }
  }
})
export default class HomePageQuestionView extends Vue {
  post_id!: number;
}
