export type LoadScreen = {
     isLoading: boolean;
     hasError: boolean;
     errorMessage: string|null;
     help_url: string|null;
     server_error: boolean;
}

export type State = {
     screen: LoadScreen|null,
}

const state: State = {
     screen: {
          isLoading: true,
          hasError: false,
          errorMessage: null,
          help_url: null,
          server_error: false,
     },
}

const getters = {
     isLoading: (state: State):boolean => Boolean(state.screen && state.screen.isLoading),
     hasError: (state: State):boolean => Boolean(state.screen && state.screen.hasError),
     errorMessage: (state: State):string|null => state.screen ? state.screen.errorMessage :  null,
     hasHelp: (state: State): string|null => state.screen?.help_url || null,
     isServerError: (state: State): boolean => state.screen?.server_error || false,
}

const actions = {
     
}

const mutations = {
     setScreen(state: State, screen: LoadScreen) {
          state.screen = screen
     },
     setLoading(state: State, isLoading: boolean) {
          state.screen = {
               isLoading,
               hasError: false,
               errorMessage: null,
               help_url: null,
               server_error: false,
          }
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}