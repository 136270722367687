type DataSet = Array<any>|null

export type State = {
     dataSet: DataSet;
     isRequired: boolean;
}

const state: State = {
     dataSet: [],
     isRequired: false,
}

const getters = {
     getDataset: (state: State): DataSet => state.dataSet,
     isRequired: (state: State): boolean => state.isRequired
}

const actions = {
     
}

const mutations = {
     setDataset(state: State, data: State) {
          state.dataSet = data.dataSet
          state.isRequired = data.isRequired
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}