
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import NavLink from './NavLink.vue'
import NavHomeIcon from '@/components/icons/NavHome.vue'
import NavMessagesIcon from '@/components/icons/NavMessages.vue'
import NavClockIcon from '@/components/icons/NavClock.vue'
import NavHeartIcon from '@/components/icons/NavHeart.vue'
import SideBarButton from './assets/SideBarButton.vue'
import Plus from "@/components/icons/Plus.vue";

@Options({
     computed: {
          ...mapGetters({ isAuth: 'auth/isAuthenticated', user: 'auth/user', canUpload: 'postTimer/canUpload', }),
     },
     components: {
       Plus,
          SideBarButton,
          NavLink,
          NavHomeIcon,
          NavMessagesIcon,
          NavClockIcon,
          NavHeartIcon,
     },
     props: {
          page: String || null
     },
     methods: {
          isActive(path: string): boolean {
               if(this.$route.path == path) return true
               return false
          }
     },
})

export default class BottomBar extends Vue {
     page !: string|null;
}
