
import { Options, Vue } from 'vue-class-component';
import AppLayout from './AppLayout.vue'
import AuthIcon from '@/components/icons/Auth.vue'
import AuthCenterBox from '@/components/auth/AuthCenterBox.vue'
import Button from '@/components/auth/Button.vue'
import GoogleIcon from '@/components/icons/Google.vue'
import HrWithText from '@/components/auth/HrWithText.vue'
import Separator from '@/components/Separator.vue';
import LoginIcon from '@/components/icons/Login.vue'
import AuthSettingsButtonComponent from './AuthSettingsButton.vue';

@Options({
     components: {
          AppLayout,
          AuthIcon,
          AuthCenterBox,
          Button,
          GoogleIcon,
          HrWithText,
          Separator,
          LoginIcon,
          AuthSettingsButtonComponent
     },
     props: {
          title: String,
          imgHideMobile: Boolean,
          noForm: Boolean,
     },
    data() {
       return {
         googleLoading: false
       }
    },
     mounted() {
          document.body.classList.add('app-bg-img')
     },
     unmounted() {
          document.body.classList.remove('app-bg-img')
     },
    methods: {
        async googleLogin() {
          this.googleLoading = true
        }
    }
})
export default class AuthLayout extends Vue {
     title!: string;
     imgHideMobile!: boolean;
     noForm!: boolean;
}
