import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorIcon = _resolveComponent("ErrorIcon")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("img", {
      class: _normalizeClass(_ctx.classList),
      src: _ctx.src,
      alt: _ctx.alt,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args))),
      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args)))
    }, null, 42, _hoisted_1), [
      [_vShow, _ctx.loaded]
    ]),
    (!_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["animate-pulse flex items-center justify-center bg-gray-200 dark:bg-gray-800", _ctx.loaderClass])
        }, [
          (_ctx.failed)
            ? (_openBlock(), _createBlock(_component_Tippy, {
                key: 0,
                content: _ctx.$t('Failed to load image')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ErrorIcon, { class: "text-red-500 dark:text-red-400 cursor-not-allowed" })
                ]),
                _: 1
              }, 8, ["content"]))
            : (_openBlock(), _createBlock(_component_Tippy, {
                key: 1,
                content: _ctx.$t('Loading') + '...'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Spinner)
                ]),
                _: 1
              }, 8, ["content"]))
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}