
import { Options, Vue } from 'vue-class-component'
import ErrorIcon from '@/components/icons/Error.vue'
import Spinner from "@/components/icons/Spinner.vue";

@Options({
  components: {
    Spinner,
      ErrorIcon,
  },
  data() {
      return {
        loaded: false,
        failed: false
      }
  },
  methods: {
    async onLoad() {
      await this.$sleep(this.sleep == null ? 0 : this.sleep)
      this.loaded = true
    },
    async onError() {
      await this.$sleep(this.sleep == null ? 0 : this.sleep)
      this.loaded = false
      this.failed = true
    },
  },
  props: {
    src: String,
    alt: String,
    loaderClass: Object,
    classList: Object,
    sleep: Number,
  }
})
export default class InAppLink extends Vue {
  src!: string;
  alt!: string;
  loaderClass!: Array<unknown>|object;
  classList!: Array<unknown>|object;
  sleep!: number;
}
