import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-4" }
const _hoisted_2 = {
  key: 0,
  class: "text-gray-500 ml-2"
}
const _hoisted_3 = { class: "transition duration-150 bg-gray-50 dark:bg-gray-800 py-2.5 rounded-lg mt-2 drop-shadow-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}