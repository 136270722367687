import axios from '@/ts/initAxios'
import __dev_log from "@/ts/__dev_log";

const onError = () => {
     window.onerror = function(message, url, line, column, error) {
          __dev_log.log('Error!')
          axios.post('/exception', {
               message, url, line, column, error
          })
     }
}

export default onError