
import { Options, Vue } from 'vue-class-component'
import ItemWrap from '../ItemWrap.vue'
import HashtagIcon from '@/components/icons/Hashtag.vue'

@Options({
     components: {
          ItemWrap,
          HashtagIcon
     }
})
export default class Terms extends Vue {}
