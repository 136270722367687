
import { Options, Vue } from 'vue-class-component';
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Upload from '@/components/upload/Upload.vue'
import BackPage from "@/components/BackPage.vue";

@Options({
  components: {
    BackPage,
    Layout,
    Upload
  },
})
export default class UploadView extends Vue {}
