import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Box = _resolveComponent("Box")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createBlock(_component_Group, {
    title: _ctx.$t('Network')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Box, {
        title: _ctx.$t('Data-saving mode'),
        desc: _ctx.$t('No Wi-Fi? Maybe you have limited data left? Don\'t worry, turning it on will reduce your data traffic, but you\'ll get poorer quality content in return'),
        "use-slot": true,
        icon: _ctx.bandwidthIcon
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Switch, {
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$toast.warning(_ctx.$t('Not implemented'))))
          })
        ]),
        _: 1
      }, 8, ["title", "desc", "icon"]),
      _createVNode(_component_Box, {
        title: _ctx.$t('Switching off in case of wifi'),
        "use-slot": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Switch, {
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$toast.warning(_ctx.$t('Not implemented'))))
          })
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["title"]))
}