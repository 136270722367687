const colors = {
    light: {
        default: '#000000',
        purple: '#A855F7',
        greenish: '#44c765',
        reddish: '#c43f3d',
        blue: '#0c78e8',
        yellowish: '#faeb43'
    },
    dark: {
        default: '#ffffff',
        purple: '#CB99FA',
        greenish: '#8acf94',
        reddish: '#d14d4b',
        blue: '#348deb',
        yellowish: '#f5ea6c',
    }
}

export default colors