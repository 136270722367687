
import { defineComponent } from 'vue'
import soundEffects from "@/ts/sound/soundEffects";

export default defineComponent({
  methods: {soundEffects},
     props: {
          checked: Boolean
     }
})
