
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'

@Options({
     data() {
          return {
               tags: [
                    {
                         name: 'Martin Binder',
                         website: 'https://mrtn.vip',
                         role: 'owner',
                         description: 'Hi! I\'m Martin, the owner of WiZZL',
                         image: 'https://mrtn.vip/resources/img/nav.c852416e.jpg',
                    }
               ],
          }
     },
     components: {
          Sheet,
     },
     props: {
          showSheet: Boolean,
     },
})
export default class OurTeamSheetComponent extends Vue {
     showSheet!: boolean;
}
