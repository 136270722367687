import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    class: "resize-none transition-colors duration-150 block w-full px-4 py-3 text-sm rounded-lg border-2 text-gray-600 bg-gray-100 border-gray-100 hover:bg-gray-200 hover:border-gray-200 dark:text-white dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:border-primary-200 dark:active:border-primary-200 focus:border-primary-200 active:border-primary-200"
  }, null, 40, _hoisted_1))
}