
import Box from "@/components/settings/Box.vue";
import Group from "@/components/settings/Group.vue";
import Switch from "@/components/utils/Switch.vue";
import {Options, Vue} from "vue-class-component";
import BandwidthIcon from "@/components/icons/Bandwidth.vue";

@Options({
  data() {
    return {
      bandwidthIcon: <BandwidthIcon/>
    }
  },
  components: {
    Box,
    Group,
    Switch,
  },
})
export default class extends Vue {}
