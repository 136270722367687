
import {Options, Vue} from "vue-class-component";
import UserSheet from "@/components/home/sheets/UserSheet.vue";
import UserName from "@/components/home/user/UserName.vue";

type MentionType = {
  id: number;
  username: string;
  display_name: string;
  profile_image_path: string;
}

type postType = {
  description: string;
  mentions: Array<MentionType>
}

@Options({
  components: {UserName, UserSheet},
  data() {
      return {
        users: {},
        showSheet: false,
        mention_show_name: null,
      }
  },
  props: {
    post: Object
  },
  methods: {
    getMention(part: string) {
      const id = parseInt(part.replace('@m__', ''))
      return this.users[id]
    },
    mention_show(username: string) {
      this.mention_show_name = username
      this.showSheet = true
    }
  },
  beforeMount() {
    for(let mention of this.post.mentions) {
      this.users[mention.id] = mention
    }
  }
})
export default class extends Vue {
  post!: postType;
}
