import { ConnectionStatus } from '@capacitor/network';

export type State = {
     isActive: boolean,
     type: string
}

const state: State = {
     isActive: false,
     type: 'unknown'
}

const getters = {
     isActive: (state: State): boolean => state.isActive,
     getType: (state: State): string => state.type
}

const actions = {}

const mutations = {
     setStatus(state: State, status: ConnectionStatus) {
          state.isActive = status.connected
          state.type = status.connectionType
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}