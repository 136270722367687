<template>
     <svg
          fill="none"
          class="svg-inline--fa fa-w-16"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
     >
          <path
               d="M20 19v-8.5a1 1 0 0 0-.4-.8l-7-5.25a1 1 0 0 0-1.2 0l-7 5.25a1 1 0 0 0-.4.8V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1Z"
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               class="stroke-000000"
          ></path>
     </svg>
</template>

<style scoped>
svg {
     transition: fill 0.5s;
     color:currentColor;
}

a[active="true"] svg, button[active="true"] svg {
     fill: currentColor;
     color: currentColor;
}
</style>