
import { Options, Vue } from 'vue-class-component';
import BottomBar from '@/components/navs/BottomBar.vue';
import TransitionRouterView from '@/TransitionRouterView.vue'

@Options({
     components: {
          BottomBar,
          TransitionRouterView
     }
})
export default class HasNavLayout extends Vue {}
