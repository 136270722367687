import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Group, {
      title: _ctx.$t('Common')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Box, {
          title: _ctx.$t('Edit profile'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/me/edit'))),
          icon: _ctx.pencilIcon,
          desc: _ctx.$t('Change your username, update your profile picture, etc.')
        }, null, 8, ["title", "icon", "desc"]),
        _createVNode(_component_Box, {
          title: _ctx.$t('Private account'),
          icon: _ctx.lockIcon,
          useSlot: true,
          desc: _ctx.$t('Once this is turned on, no one can just follow you, you will have to accept tracking requests, and only then will they have access to the content you post and your contact details')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Switch, {
              checked: _ctx.user().is_private,
              onChange: _ctx.switchAccountType
            }, null, 8, ["checked", "onChange"])
          ]),
          _: 1
        }, 8, ["title", "icon", "desc"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Group, {
      title: _ctx.$t('Account suspension and deletion')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Box, {
          title: _ctx.$t('Suspension'),
          icon: _ctx.pauseIcon,
          desc: _ctx.$t('All your account information will be private and will not appear anywhere until you log in again (this may take several hours and you will be logged out of all your devices)')
        }, null, 8, ["title", "icon", "desc"]),
        _createVNode(_component_Box, {
          title: _ctx.$t('Delete account'),
          icon: _ctx.trashIcon
        }, null, 8, ["title", "icon"])
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}