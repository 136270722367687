
import { Options, Vue } from 'vue-class-component';
import BackPage from '@/components/BackPage.vue'
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import BioLinks from '@/components/me/BioLinks.vue'
import { mapGetters } from 'vuex'

@Options({
  computed: {
    ...mapGetters({ isAuth: 'auth/isAuthenticated', user: 'auth/user' }),
  },
  components: {
    BackPage,
    Layout,
    BioLinks,
  },
})
export default class MeView extends Vue {}
