import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-[90%] my-3 mx-auto flex flex-wrap text-left items-center" }
const _hoisted_2 = { class: "flex space-x-3 items-center" }
const _hoisted_3 = { class: "text-xl" }
const _hoisted_4 = { class: "truncate text-gray-400 dark:text-gray-600" }
const _hoisted_5 = { class: "text-primary-300 dark:text-primary-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExternalLink = _resolveComponent("ExternalLink")!
  const _component_BrowserLink = _resolveComponent("BrowserLink")!
  const _component_BioLinkMeta = _resolveComponent("BioLinkMeta")!
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createBlock(_component_Sheet, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    visibility: _ctx.showSheet
  }, {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
          return (_openBlock(), _createElementBlock("div", {
            key: link.id,
            class: "w-full my-2"
          }, [
            _createVNode(_component_BrowserLink, {
              class: "transition duration-150 border-2 hover:bg-gray-200 dark:border-gray-800 border-dashed px-3 py-4 w-full block dark:hover:bg-gray-800 rounded-lg",
              href: _ctx.appUrl(link.app_url)
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ExternalLink, { class: "text-primary-300" })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.url(link.real_url).hostname), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.url(link.real_url).pathname), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["href"]),
            (link.meta)
              ? (_openBlock(), _createBlock(_component_BioLinkMeta, {
                  key: 0,
                  link: link
                }, null, 8, ["link"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["visibility"]))
}