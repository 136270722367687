
import { Options, Vue } from 'vue-class-component';
import Common from './Common.vue'
import Separator from '@/components/Separator.vue';
import Button from '@/components/auth/Button.vue'
import SpinnerIcon from '@/components/icons/Spinner.vue'

@Options({
     data() {
          return {
               benefits: [
                    {
                         short: 'Upload more types of content 📸',
                         long: 'Upload likely anything, videos, songs, voice messages (and it will keep growing) 😉'
                    },
                   {
                     short: 'Answer anything, anytime ⏩',
                     long: 'Whoa, like a time machine, if you don\'t want to wait or keep collecting points to answer, this is for you ⏱️'
                   },
                    {
                         short: 'Premium subscriber badge 💎',
                         long: 'Stand out from the crowd with the premium subscriber badge, but it\'s not just for that, with the badge your posts will receive a +10% boost in visits 😎'
                    },
                    {
                         short: 'Custom username color 🖌️',
                         long: 'Yes, you heard it right, you can set the color of your username for both dark and light mode users individually 🎨'
                    },
                    {
                         short: 'Be animated 🎞️',
                         long: 'Use gifs as profile pictures and banners 😇'
                    },
                    {
                         short: 'Post ads 20% cheaper 📢',
                         long: 'Want to raise your profile? With Premium you can do it with a 20% discount 💰'
                    },
                    {
                         short: 'The transaction fee is 10% less for each payment you receive 💳',
                         long: 'Every gift you get for your posts will now be worth 10% more, because our app charges that much less 💸'
                    },
                    {
                         short: 'Supporting the app 🤝',
                         long: 'By purchasing premium, you can make a big contribution to the future of the app, and have more power to make changing decisions 🍃'
                    }
               ],
               showAllBenefits: false,
          }
     },
     props: {
          showSheet: Boolean,
     },
     components: {
          Common,
          Separator,
          Button,
          SpinnerIcon
     },
})
export default class GifSheetComponent extends Vue {
     showSheet!: boolean;
}
