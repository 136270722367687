
import { Options, Vue } from 'vue-class-component';
import BackPage from '@/components/BackPage.vue'
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Page from '@/components/me/Page.vue'
import { mapGetters } from 'vuex'

@Options({
     computed: {
          ...mapGetters({ isAuth: 'auth/isAuthenticated', user: 'auth/user' }),
     },
     components: {
          BackPage,
          Layout,
          Page,
     },
})
export default class MeView extends Vue {}
