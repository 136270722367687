import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["mode"]
const _hoisted_2 = { class: "flex h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    mode: _ctx.app ? 'app' : 'web'
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full md:max-w-screen-sm mx-auto", {'px-3 lg:px-0': !_ctx.removePadding}])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ])
  ], 10, _hoisted_1))
}