import { App } from 'vue';
import Toast from "vue-toastification";
import VueTippy from 'vue-tippy'
import router from '@/router'
import store from '@/store'
import { createBottomSheet } from 'bottom-sheet-vue3'
import 'tippy.js/themes/light.css'
import i18n from "@/i18n";

export default function addUsings(app: App): void {
     app.use(VueTippy, {
          directive: 'tippy', // => v-tippy
          component: 'Tippy', // => <tippy/>
          theme: 'light',
     })
     
     app.use(Toast, {});
     
     app.use(store)

     app.use(createBottomSheet())

     app.use(i18n)
     
     app.use(router)
}