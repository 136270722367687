
import Sheet from "@/components/utils/Sheet.vue";
import Button from "@/components/auth/Button.vue";
import Spinner from "@/components/icons/Spinner.vue";
import Premium from "@/components/icons/Premium.vue";
import Separator from "@/components/Separator.vue";
import LinkButton from "@/components/mobile/LinkButton.vue";
import {Options, Vue} from "vue-class-component";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import BioLinkMeta from "@/components/utils/BioLinkMeta.vue";
import ExternalLink from "@/components/icons/ExternalLink.vue";

@Options({
  components: {ExternalLink, BioLinkMeta, BrowserLink, LinkButton, Separator, Button, Premium, Spinner, Sheet},
  props: {
    showSheet: Boolean,
    links: Object,
  },
  methods: {
    appUrl(url: string) {
      return window.config?.server?.LINK_REDIRECT_URL?.replace(':id', url)
    },
    url(url: string) {
      return new URL(url)
    }
  }
})
export default class extends Vue {}
