import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_3 = { class: "mx-auto" }
const _hoisted_4 = { class: "mx-auto text-center" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("button", {
    shrink: "",
    disabled: _ctx.isLoading,
    class: _normalizeClass(["titleFont transition-colors duration-150 px-5 py-2 rounded-lg w-full", {
          'flex items-center':_ctx.hasIcon, 
          'bg-gray-100 text-gray-600 hover:bg-gray-200 active:bg-gray-300 focus:bg-gray-300 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:active:bg-gray-600 dark:focus:bg-gray-600': _ctx.grayTheme,
          'bg-primary-300 text-white hover:bg-primary-400 active:bg-primary-500 focus:bg-primary-500': !_ctx.grayTheme,
     }])
  }, [
    (_ctx.hasIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "icon")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.isLoading)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('Loading')) + " ", 1),
            _createVNode(_component_Spinner)
          ]))
    ])
  ], 10, _hoisted_1))
}