import axios from '@/ts/initAxios'

class wForm {

    #resource = '';
    fields: object = {}
    errors = {}
    processing = false
    method = 'post'
    message: string|null = null
    success = false
    finished = false

    constructor(resource: string, fields: object) {
        this.#resource = resource
        this.fields = fields
    }

    post() { this.method = 'post' }
    put() { this.method = 'put' }
    $reset(fields: object) {
        this.errors = {}
        this.processing = false
        this.message = null
        this.success = false
        this.finished = false
        this.fields = fields
    }

    submit = async () => {
        this.processing = true
        let res;
        try {
            switch(this.method) {
                case 'post':
                    res = await axios.post(this.#resource, this.fields);
                    break;
                case 'put':
                    res = await axios.put(this.#resource, this.fields);
                    break;
            }
        } catch(e: any) {
            console.error(e.message)
            res = e.response
        }

        if(res.status !== 200) {
            this.success = false
            let unknown = true
            if(res.data.message) {
                this.message = res.data.message
                unknown = false
            }
            if(res.data.errors) {
                this.errors = res.data.errors
                unknown = false
            }
            if(unknown) this.message = 'An unknown error occured'
        } else this.success = true
        this.finished = true
        this.processing = false;
        return res
    }
}

export default function (resource: string, fields: never) {
    return new wForm(resource, fields);
}