
import { Options, Vue } from 'vue-class-component';
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import Form from '@/components/auth/Form.vue'
import InAppLink from '@/components/utils/InAppLink.vue'

@Options({
  components: {
    AuthLayout,
    Form,
    InAppLink
  },
})
export default class AuthView extends Vue {}
