import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center w-100 mb-[.35px] mx-2" }
const _hoisted_2 = {
  key: 1,
  class: "appFont cursor-not-allowed"
}
const _hoisted_3 = { class: "text-gray-600" }
const _hoisted_4 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserProfileImage = _resolveComponent("UserProfileImage")!
  const _component_UserName = _resolveComponent("UserName")!
  const _component_Dots = _resolveComponent("Dots")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_UserSheet = _resolveComponent("UserSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserProfileImage, {
        user: _ctx.user,
        is_anonymous: _ctx.is_anonymous
      }, null, 8, ["user", "is_anonymous"]),
      _createElementVNode("div", null, [
        (!_ctx.is_anonymous)
          ? (_openBlock(), _createBlock(_component_UserName, {
              key: 0,
              class: "appFont",
              user: _ctx.user,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSheet = true))
            }, null, 8, ["user"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('Anonymous')), 1)),
        _createElementVNode("p", _hoisted_3, [
          _createElementVNode("small", null, _toDisplayString(_ctx.$ta().format(new Date(_ctx.posted_at), 'mini-now')), 1)
        ])
      ]),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("button", {
          class: "transition duration-150 bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 focus:bg-gray-200 dark:focus:bg-gray-900 p-0.5 px-1 rounded",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('dotsClicked')))
        }, [
          _createVNode(_component_Tippy, {
            content: _ctx.$t('Options')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Dots, { class: "rotate-90 text-gray-500 dark:text-gray-300" })
            ]),
            _: 1
          }, 8, ["content"])
        ])
      ])
    ]),
    (_ctx.user && _ctx.showSheet)
      ? (_openBlock(), _createBlock(_component_UserSheet, {
          key: 0,
          showSheet: _ctx.showSheet,
          username: _ctx.user.username,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSheet = false))
        }, null, 8, ["showSheet", "username"]))
      : _createCommentVNode("", true)
  ], 64))
}