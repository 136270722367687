import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLoader = _resolveComponent("MainLoader")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_TransitionRouterView = _resolveComponent("TransitionRouterView")!
  const _component_ProfileSetup = _resolveComponent("ProfileSetup")!
  const _component_HasNavLayout = _resolveComponent("HasNavLayout")!

  return (_ctx.isLoading || !_ctx.appLoaded)
    ? (_openBlock(), _createBlock(_component_MainLoader, { key: 0 }))
    : (_ctx.hasError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 1,
          message: _ctx.errorMessage,
          help_url: _ctx.hasHelp,
          server_error: _ctx.isServerError
        }, null, 8, ["message", "help_url", "server_error"]))
      : (_ctx.isActive)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (!_ctx.hasNavbar() && !_ctx.isRequired)
              ? (_openBlock(), _createBlock(_component_TransitionRouterView, { key: 0 }))
              : (_ctx.isRequired)
                ? (_openBlock(), _createBlock(_component_ProfileSetup, {
                    key: 1,
                    dataset: _ctx.getDataset
                  }, null, 8, ["dataset"]))
                : (_openBlock(), _createBlock(_component_HasNavLayout, { key: 2 }))
          ], 64))
        : (_openBlock(), _createBlock(_component_ErrorPage, {
            key: 3,
            message: _ctx.$t('Network connection error')
          }, null, 8, ["message"]))
}