import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-2 text-gray-500"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserName = _resolveComponent("UserName")!
  const _component_UserSheet = _resolveComponent("UserSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.post.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.post.description.bioReplace().split(/(@\w+)/g), (part, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (part.startsWith('@m__') && _ctx.getMention(part))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.mention_show(_ctx.getMention(part).username))
                  }, [
                    _createVNode(_component_UserName, {
                      displayName: true,
                      class: "!text-base text-blue-400 bg-gray-200 dark:bg-gray-700 px-2 py-0.5 rounded-lg truncate",
                      user: _ctx.getMention(part)
                    }, null, 8, ["user"])
                  ], 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: part
                  }, null, 8, _hoisted_3))
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSheet)
      ? (_openBlock(), _createBlock(_component_UserSheet, {
          key: 1,
          showSheet: _ctx.showSheet,
          username: _ctx.mention_show_name,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSheet = false))
        }, null, 8, ["showSheet", "username"]))
      : _createCommentVNode("", true)
  ], 64))
}