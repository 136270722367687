
import {Options, Vue} from "vue-class-component";
import colors from "@/components/me/premiumColors";
import Separator from "@/components/Separator.vue";
import Premium from "@/components/icons/Premium.vue";
import Button from "@/components/auth/Button.vue";
import Spinner from "@/components/icons/Spinner.vue";

@Options({
  components: {Spinner, Button, Premium, Separator},
  data() {
      return {
        colors,
        cube_profile_image: false,
        username_colors: {
          light: 'default',
          dark: 'default',
        },
        isLight: false,
        processing: false,
      }
  },
  mounted() {
    this.cube_profile_image = this.premiumCubeImage ? true : false
    this.isLight = this.$theme.isLight()
    if(this.user().premium_settings) {
      this.username_colors.light = this.user().premium_settings.light_mode_username_color_name
      this.username_colors.dark = this.user().premium_settings.dark_mode_username_color_name
    }
  },
  computed: {
    premiumCubeImage() {
      return(
          this.user().is_premium && this.user().premium_settings &&
          this.user().premium_settings.cube_profile_image
      )
    }
  },
  methods: {
    async update() {
      this.processing = true
      let res
      try {
        res = await this.axios.put('/user/update_premium', {
          cube_profile_image: this.cube_profile_image,
          light_mode_username_color_name: this.username_colors.light,
          dark_mode_username_color_name: this.username_colors.dark,
        })
      } catch(e: any) {
        console.error(e.message)
        this.processing = false
        this.$toast.error(this.$t('error.unknown'))
        return
      }
      if(res.status == 200) {
        this.processing = false
        this.$toast.success(this.$t('Successfully updated'))
        if(res.data.user) this.$store.commit('auth/setUserLoggedIn', res.data.user)
      }
    }
  }
})
export default class extends Vue {}
