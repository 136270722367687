import SignInView from '@/views/Auth/SignIn.vue'
import SignUpView from '@/views/Auth/SignUp.vue'
import AuthView from '@/views/Auth/AuthView.vue'
import ForgotPasswordView from '@/views/Auth/ForgotPassword.vue'
import GuardTypes from '../guard';

const theme: NonNullable<unknown> = {
     statusbarColorIndex: 0,
     navbarColorIndex: 0
};

export default [
     {
          path: '/auth',
          name: 'auth.auth',
          component: AuthView,
          meta: {
               auth: GuardTypes.ACCESS_NO_LOGIN,
               theme
          },
     },
     {
          path: '/auth/login',
          name: 'auth.login',
          component: SignInView,
          meta: {
               auth: GuardTypes.ACCESS_NO_LOGIN,
               theme
          },
     },
     {
          path: '/auth/register',
          name: 'auth.register',
          component: SignUpView,
          meta: {
               auth: GuardTypes.ACCESS_NO_LOGIN,
               theme
          },
     },
     {
          path: '/auth/forgot-password',
          name: 'auth.forgot-password',
          component: ForgotPasswordView,
          meta: {
               auth: GuardTypes.ACCESS_NO_LOGIN,
               theme
          },
     }
]