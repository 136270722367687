
import { Options, Vue } from 'vue-class-component'
import LinkButton from '@/components/mobile/LinkButton.vue'

@Options({
     data(){
          return {
               showNames: false,
          }
     },
     components: {
          LinkButton
     },
     props: {
          name: String || null,
          active: Boolean,
          disableIconColors: Boolean || null,
     }
})

export default class NavLink extends Vue {
     name!: string|null;
     active!: boolean;
     disableIconColors!: boolean|null;
}
