import { State, Data } from "./types/posts";

const state: State = {
     data: [],
     path: null,
     per_page: 5,
     next_cursor: null,
     next_page: null,
     prev_cursor: null,
     prev_page_url: null,
}

const getters = {
     data: (state: State): Array<Data>|[] => state.data,
     cursor: (state: State): string|null => state.next_cursor,
}

const actions = {
     
}

const mutations = {
     setData(state: State, data: State) {
          const datas = [...state.data, ...data.data]
          state.data = datas
          state.path = data.path
          state.per_page = data.per_page
          state.next_cursor = data.next_cursor
          state.next_page = data.next_page
          state.prev_cursor = data.prev_cursor
          state.prev_page_url = data.prev_page_url
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}