
import { Options, Vue } from 'vue-class-component'
import isApp from "@/ts/mobile/isApp";
import { Browser } from '@capacitor/browser';

@Options({
  methods: {
    isApp,
    async browser() {
      const url = this.href
      await Browser.open({ url })
    }
  },
  props: {
    href: String,
  }
})
export default class BrowserLink extends Vue {
  to!: string;
}
