
import { Options, Vue } from 'vue-class-component'
import isApp from "@/ts/mobile/isApp";

@Options({
   data() {
      return {
        classList: 'transition duration-150 px-2 py-1 text-primary-300 text-sm rounded-lg hover:bg-gray-100 focus:bg-gray-200 active:bg-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-700'
      }
   },
   methods: {
     isApp,
     action() {
       if(this.to) this.$router.push(this.to)
     }
   },
   props: {
        to: String,
   }
})
export default class InAppLink extends Vue {
     to!: string;
}
