import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "lg:grid grid-cols-2 grid-rows-1 gap-3 space-y-3 lg:space-y-0" }
const _hoisted_2 = { class: "transition duration-150 bg-blue-300 hover:bg-blue-200 dark:bg-blue-400 dark:hover:bg-blue-300 p-3 px-5 rounded-2xl cursor-pointer" }
const _hoisted_3 = { class: "appFont text-2xl text-white" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "transition duration-150 bg-yellow-300 hover:bg-yellow-200 dark:bg-yellow-400 dark:hover:bg-yellow-300 p-3 px-5 rounded-2xl cursor-pointer" }
const _hoisted_7 = { class: "appFont text-2xl text-white" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, [
        (_ctx.questions != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.questions), 1))
          : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('Posts')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h4", _hoisted_7, [
        (_ctx.answers != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.answers), 1))
          : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
      ]),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Answers')), 1)
    ])
  ]))
}