export type VibrationState = {
     style: string;
     enabled: boolean;
}

export type State = {
     navbarBlurry: boolean;
     vibration: VibrationState;
     pushNotifications: boolean;
     language: string|null;
}

const state: State = {
     navbarBlurry: false,
     vibration: {
          style: 'Medium',
          enabled: true
     },
     pushNotifications: true,
     language: null,
}

const getters = {
     allowVibration: (state: State): boolean => state.vibration.enabled,
     getVibrationStyle: (state: State): string => state.vibration.style,
     navbarBlurry: (state: State): boolean => state.navbarBlurry,
     pushNotificationsEnabled: (state: State): boolean => state.pushNotifications,
     getLanguage: (state: State): string|null => state.language
}

const actions = {}

const mutations = {
     setVibrationStyle(state: State, payload: VibrationState) {
          state.vibration = payload
     },
     setNavbarBlurry(state: State, style: boolean) {
          state.navbarBlurry = style
     },
     setPushNotificationEnabled(state: State, payload: VibrationState) {
          state.vibration = payload
     },
     setLanguage(state: State, payload: string) {
          state.language = payload
     },
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}