
import { Options, Vue } from 'vue-class-component'

@Options({
     data() {
          return {
               opt: 0,
          }
     },
     props: {
          options: Array,
          sel: String,
     },
     beforeMount() {
          this.opt = this.sel
     }
})
export default class TextInput extends Vue {
     options!: Array<string>;
     sel!: string;
}
