
import {Options, Vue} from "vue-class-component";
import Button from "@/components/auth/Button.vue";
import 'cropperjs/dist/cropper.css';
import VueCropper from 'vue-cropperjs';
import store from "@/store";
import {tokenGet} from "@/ts/auth/tokenManager";
@Options({
    data() {
        return {
          img: null,
          p_events: {},
          processing: false,
        }
    },
    components: {
      Button,
      VueCropper
    },
    methods: {
      onFileChange(e: any) {
        const file = e.target.files[0];
        this.img = {
          file: file,
          url: URL.createObjectURL(file)
        };
      },
      async upload() {
        this.process(true)
        const config = {
          onUploadProgress: this.progress
        };
        const data = this.getImageData()

        let res
        try {
          res = await this.axios.post(window.config.server.CDN_HOST + '/api/v1/upload/image/profile.json', data, config)
        } catch(e: any) {
          console.error(e.message)
          this.process(false)
          this.$toast.error('Failed to upload image to the CDN')
          return
        }
        if(res && res.status == 200 && res.data?.data_url) {
          if(res.data?.verification == 'required') await this.verifyUrl(res.data.data_url)
        } else {
          this.$toast.error(this.$t('Something went wrong on the CDN API'))
        }
      },
      async verifyUrl(url: string) {
        let res
        try {
          res = await this.axios.put('/user/profile_image', {
            'image_url': url,
          })
        } catch(e: any) {
          console.error(e.message)
          this.process(false)
          this.$toast.error(this.$t('Failed to verify image upload'))
          return
        }
        if(res.status == 200 && res.data?.user) {
          this.$store.commit('auth/setUserLoggedIn', res.data.user)
          this.process(false)
          this.$toast.success('Uploaded successfully')
          this.$router.push('/me/edit')
        } else {
          this.process(false)
          this.$toast.error(this.$t('Failed to verify image upload'))
        }
      },
      process(is: boolean) {
        if(is) {
          this.processing = true
          this.p_events = {pointerEvents:'none'}
        } else {
          this.processing = false
          this.p_events = {}
        }
      },
      getImageData() {
        const url = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpg')
        const data = new FormData()
        data.append('content_base64', url.substring(url.indexOf(',')+1))
        return data
      },
      progress(progressEvent: any) {
        if(progressEvent.total !== null) this.$store.commit('requestLoader/setStatus', {
          progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          working: true
        })
      },
      getFormDataSize(formData: FormData) {
        return [...formData].reduce((size, [name, value]) => size + (typeof value === 'string' ? value.length : value.size), 0)
      }
    },
    /*mounted() {
      //this.img = this.$profileImage(this.user().profile_image_path)
    }*/
})
export default class extends Vue {}
