
import RequestLoader from "@/components/navs/RequestLoader.vue";
import {Options, Vue} from "vue-class-component";
import MagicWand from "@/components/icons/MagicWand.vue";
import HideKeyboard from "@/components/icons/HideKeyboard.vue";
import {Keyboard} from "@capacitor/keyboard";
import isApp from "@/ts/mobile/isApp";
import {mapGetters} from "vuex";
import Button from "@/components/auth/Button.vue";
import Tag from '@/components/icons/Tag.vue'

@Options({
  data() {
    return {
      helperOpen: false,
      helperStyle: {},
      text_types: {
        '<b>Bold text</b>': ['**', '**'],
        '<i>Italic text</i>': ['*', '*'],
        '<span class="vfdbg-text-hl">Highlight</span>': ['`', '`'],
      },
      taggerOpen: false,
    }
  },
  components: {
    Tag,
    Button,
    HideKeyboard,
    MagicWand,
    RequestLoader
  },
  computed: {
      ...mapGetters({
        keyboardHeight: "appearence/keyboardHeight"
      })
  },
  methods: {
    async closeKeyboard() {
        if(isApp()) {
          await Keyboard.hide()
          this.$store.commit('appearence/setkeyboardOpen', {open:false})
        }
        this.taggerOpen = false
        this.helperOpen = false
    },
    async openHelper() {
      await this.closeKeyboard()
      this.$store.commit('appearence/setkeyboardOpen', {open:true})
      this.helperOpen = true
    },
    paste(val: Array<string>) {
      const text = val[0] + 'Your text here' + val[1]
      this.$log.log(text)
      this.$toast.warning('Not implemented')
    },
    async openTagger() {
      await this.closeKeyboard(true)
      this.$store.commit('appearence/setkeyboardOpen', {open:true})
      this.taggerOpen = true
    }
  },
})
export default class extends Vue {}
