Object.defineProperty(String.prototype, 'capitalize', {
     value: function() {
          return this.charAt(0).toUpperCase() + this.slice(1);
     },
     enumerable: false
});

Object.defineProperty(String.prototype, 'humanWords', {
     value: function() {
          const words = this.match(/[A-Za-z][a-z]*/g) || [];
          return words.join(" ").capitalize();
     },
     enumerable: false
});

Object.defineProperty(String.prototype, 'htmlescape', {
     value: function() {
          const map = {
               '&': '&amp;',
               '<': '&lt;',
               '>': '&gt;',
               '"': '&quot;',
               "'": '&#039;'
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          return this.replace(/[&<>"']/g, function(m: string) { // @ts-ignore
               return map[m];
          });
     },
     enumerable: false
});

Object.defineProperty(Number.prototype, 'toMinutesAndSeconds', {
     value: function() {
          return ((this/60000).toFixed(2)).replace('.',':');
     },
     enumerable: false
});

Object.defineProperty(String.prototype, 'bioReplace', {
     value: function() {
          return this.htmlescape().replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
              .replace(/\*(.*?)\*/g, '<i>$1</i>')
     },
     enumerable: false
});