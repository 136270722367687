import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import store from '@/store';
import GuardTypes from './guard';
import AppRoutes from './routes/app'
import LoginRoutes from './routes/login'
import SubscriptionsRoutes from './routes/subscriptions'

const routes: Array<RouteRecordRaw> = [
     ...AppRoutes,
     ...LoginRoutes,
     ...SubscriptionsRoutes,
     {
          path: '/:pathMatch(.*)',
          name: 'notFound',
          component: () => import(/* webpackChunkName: "not-found" */ '@/views/Errors/NotFound.vue')
     }
]

const router = createRouter({
     history: createWebHistory(process.env.BASE_URL),
     routes
})

let checked = false
async function checkAuthState() {
     if(!checked){
          await store.dispatch('auth/fetchUser')
     }
     checked = true
}

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
     await checkAuthState()
     const auth = store.getters['auth/isAuthenticated']
     if(
          (!auth && !to.meta.auth) || 
          (!auth && to.meta.auth === GuardTypes.ACCESS_LOGIN)
     ) next('/auth')
     else if(
          to.meta.auth === GuardTypes.ACCESS_NO_LOGIN && auth
     ) next('/')
     else next()
     if(!to.meta.keepLoader) store.commit('loadScreen/setScreen', {
          isLoading: false,
          hasError: false,
     })
})

export default router