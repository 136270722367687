import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xl flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" }
const _hoisted_2 = { class: "flex-1 ml-3 whitespace-nowrap appFont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createBlock(_component_LinkButton, {
    active: "true",
    to: "/app",
    class: "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _: 3
  }))
}