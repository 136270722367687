class ConsoleLogger {

    private isDev(): boolean {
        return process.env.NODE_ENV === 'development'
    }

    log(...vars: unknown[]): void {
        if(!this.isDev()) return
        console.log(...vars)
    }

    warn(...vars: unknown[]): void {
        if(!this.isDev()) return
        console.warn(...vars)
    }

    error(...vars: unknown[]): void {
        if(!this.isDev()) return
        console.error(...vars)
    }

    info(...vars: unknown[]): void {
        if(!this.isDev()) return
        console.info(...vars)
    }

    table(...vars: unknown[]): void {
        if(!this.isDev()) return
        console.table(...vars)
    }

}

export default new ConsoleLogger()