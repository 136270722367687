
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import NavLink from './NavLink.vue'
import NavHomeIcon from '@/components/icons/NavHome.vue'
import NavMessagesIcon from '@/components/icons/NavMessages.vue'
import NavClockIcon from '@/components/icons/NavClock.vue'
import NavHeartIcon from '@/components/icons/NavHeart.vue'
import SideBar from './SideBar.vue'
import RequestLoader from "./RequestLoader.vue";

import vibrate from '@/ts/mobile/vibrate'

import MobileTypeHelper from "@/components/navs/MobileTypeHelper.vue";
import Plus from "@/components/icons/Plus.vue";
import ProfileImageLazy from "@/components/users/ProfileImageLazy.vue";

@Options({
     data() {
          return {
               connectingProgress: 0,
               WTo: '/app/upload'
          }
     },
     computed: {
          ...mapGetters({ 
               isAuth: 'auth/isAuthenticated', 
               user: 'auth/user',
               navStyle: 'settings/navbarBlurry',
               allowVibration: 'settings/allowVibration',
               vibrationStyle: 'settings/getVibrationStyle',
               canUpload: 'postTimer/canUpload',
          }),
     },
     components: {
       ProfileImageLazy,
       Plus,
       MobileTypeHelper,
          NavLink,
          NavHomeIcon,
          NavMessagesIcon,
          NavClockIcon,
          NavHeartIcon,
          SideBar,
          RequestLoader
     },
     props: {
          page: String || null
     },
     methods: {
          isActive(path: string): boolean {
               if(this.$route.path == path) return true
               return false
          },
          async uploadTimer() {
            if(!this.canUpload) {
              this.$toast.warning(this.$t('Hey! You\'ll have to wait'))
              if(this.allowVibration) {
                await vibrate(this.vibrationStyle, 500)
              }
            }
          },
     },
     async beforeMount() {
          const style = await this.$db.get('settings.bottom-nav-style') || false
          this.$store.commit('settings/setNavbarBlurry', style)
     },
     mounted() {
          this.$store.commit('appearence/setbottomNavSize',this.$refs.wizzlFooterNav?.clientHeight)
     }
})

export default class BottomBar extends Vue {
     page !: string|null;
}
