
import { Options, Vue } from 'vue-class-component'
import Group from './Group.vue'
import Box from './Box.vue'
import TeamIcon from '@/components/icons/Team.vue'
import SupportHeadsetIcon from '@/components/icons/SupportHeadset.vue'
import DonationIcon from '@/components/icons/Donation.vue'
import OurTeamSheet from './sheets/OurTeamSheet.vue'
import isApp from "@/ts/mobile/isApp";
import {Browser} from "@capacitor/browser";

@Options({
     data() {
          return {
               icons: {
                    team: <TeamIcon/>,
                    sheadset: <SupportHeadsetIcon/>,
                    donation: <DonationIcon/>,
               },
               sheet: {
                    ot: false,
               }
          }
     },
     components: {
          Group,
          Box,
          OurTeamSheet
     },
    methods: {
      async supportUrl() {
        const url = this.$supportLink('/support')
        if(!isApp()) {
          window.open(url, '_blank')
        } else {
          await Browser.open({url})
        }
      },
    }
})

export default class MainLoader extends Vue {}
