
import { Options, Vue } from 'vue-class-component';
import premiumColors from "@/components/me/premiumColors";
import Premium from "@/components/icons/Premium.vue";

@Options({
  components: {Premium},
  data() {
    return {
      premiumColors,
    }
  },
  props: {
    user: Object,
    displayName: Boolean,
  },
  computed: {
    getPremiumStyle() {
      if(this.user && this.user.is_premium && this.user.premium_settings) {
        if(this.$theme.isLight() && this.user.premium_settings.light_mode_username_color_name) return {color: this.premiumColors.light[this.user.premium_settings.light_mode_username_color_name] + '!important'}
        else if(this.user.premium_settings.dark_mode_username_color_name) return {color: this.premiumColors.dark[this.user.premium_settings.dark_mode_username_color_name] + '!important'}
      }
      return {}
    }
  }
})
export default class extends Vue {
  user!: object;
  displayName!: boolean;
}
