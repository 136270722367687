
import Sheet from "@/components/utils/Sheet.vue";
import Button from "@/components/auth/Button.vue";
import Spinner from "@/components/icons/Spinner.vue";
import Premium from "@/components/icons/Premium.vue";
import Separator from "@/components/Separator.vue";
import LinkButton from "@/components/mobile/LinkButton.vue";
import {Options, Vue} from "vue-class-component";
import UserProfileImage from "@/components/home/user/UserProfileImage.vue";
import UserName from "@/components/home/user/UserName.vue";

@Options({
  data() {
      return {
        loaded: false,
        user: null,
        requested: false,
        followed: false
      }
  },
  components: {UserName, UserProfileImage, LinkButton, Separator, Button, Premium, Spinner, Sheet},
  props: {
    showSheet: Boolean,
    username: String,
  },
  async mounted() {
    await this.loadUp()
  },
  computed: {
    addImageHeader() {
      return false
    }
  },
  methods: {
    url(url: string) {
        return new URL(url)
    },
    appUrl(url: string) {
      return window.config?.server?.LINK_REDIRECT_URL?.replace(':id', url)
    },
    async loadUp() {
      let res
      try {
        res = await this.axios.get('/user/' + this.username)
      } catch(e: any) {
        console.error(e.message)
        this.$toast.error('Failed')
        this.$emit('close')
      }
      if(res.data.user) {
        this.user = res.data.user
        this.loaded = true
        this.followed = this.user.is_followed
      } else {
        this.$toast.error('Failed')
        this.$emit('close')
      }
    }
  }
})
export default class extends Vue {}
