import { createStore, createLogger } from 'vuex'
import auth from './modules/auth'
import loadScreen from './modules/loadScreen'
import network from './modules/network'
import settings from './modules/settings'
import requestLoader from './modules/requestLoader'
import profileSetupState from './modules/profileSetupState'
import posts from './modules/posts'
import appearance from './modules/appearance'
import postTimer from "@/store/modules/postTimer";
import playable from './modules/playable'

export default createStore({
  plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : [],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    loadScreen,
    network,
    settings,
    requestLoader,
    profileSetupState,
    posts,
    appearance,
    postTimer,
    playable
  }
})
