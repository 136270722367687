
import { Options, Vue } from 'vue-class-component';
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import Form from '@/components/auth/Form.vue'
import InAppLink from '@/components/utils/InAppLink.vue'
import { API_ROUTES } from '@/ts/apiRoutes'

@Options({
  setup() {
     const formRoute = API_ROUTES.auth.forgotPassword;
     return { formRoute }
  },
  data(){
    return {
      fields: [
        {
          placeholder: this.$t('Username or email'),
          icon: 'email',
          type: 'text',
          name: 'user'
        },
      ]
    }
  },
  components: {
    AuthLayout,
    Form,
    InAppLink
  },
})
export default class SignInView extends Vue {}
