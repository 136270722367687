<template>
  <div class="border-2 border-primary-200 dark:border-primary-400 px-5 py-1 bg-gray-50 dark:bg-gray-800 rounded-lg">
    <h4 class="appFont text-2xl mt-3">{{$t('Want to be more unique?')}}</h4>
    <small class="text-gray-500">
      {{$t('Custom username color, animated profile picture, premium subscriber badge, shall I list more?')}}
    </small>
    <router-link to="/subscribe/premium" shrink class="transition duration-150 !text-white bg-gradient-to-r from-indigo-500 via-purple-400 to-pink-500 hover:opacity-80 focus:opacity-60 block appFont rounded-lg px-3 py-2 w-full text-center mt-3 mb-3">
      {{$t('More information')}}
    </router-link>
  </div>
</template>
<script setup lang="ts">
</script>