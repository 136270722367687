
import Box from "@/components/settings/Box.vue";
import Group from "@/components/settings/Group.vue";
import Switch from "@/components/utils/Switch.vue";
import PencilIcon from "@/components/icons/Pencil.vue";
import KeyIcon from '@/components/icons/Key.vue'
import PauseIcon from "@/components/icons/Pause.vue";
import TrashIcon from "@/components/icons/Trash.vue";
import LockIcon from '@/components/icons/Lock.vue';
import {Options, Vue} from "vue-class-component";
import AddressIcon from "@/components/icons/Address.vue";

@Options({
  data() {
    return {
      lockIcon: <LockIcon/>,
      keyIcon: <KeyIcon/>,
      pencilIcon: <PencilIcon/>,
      pauseIcon: <PauseIcon/>,
      trashIcon: <TrashIcon class="!text-red-400" />,
      addressIcon: <AddressIcon/>,
    }
  },
  components: {
    Box,
    Group,
    Switch
  },
})
export default class AccountPage extends Vue {}
