
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import ImageIcon from '@/components/icons/Image.vue'
import UserFollowBox from './UserFollowBox.vue'
import Separator from '@/components/Separator.vue'
import Progress from './Progress.vue'
import Button from '@/components/auth/Button.vue'
import Activity from './Activity.vue'
import Pencil from "@/components/icons/Pencil.vue";
import Input from './Input.vue'
import Textarea from "@/components/me/Textarea.vue";
import Spinner from "@/components/icons/Spinner.vue";
import LinkButton from "@/components/mobile/LinkButton.vue";
import BuyMoreUniqueContainer from "@/components/premium/BuyMoreUniqueContainer.vue";
import PremiumSettings from "@/components/me/PremiumSettings.vue";
import ProfileImageLazy from "@/components/users/ProfileImageLazy.vue";
@Options({
  data() {
    return {
      editable_field_names: [
          'username',
          'display_name',
          'bio',
      ],
      field_casts: {
        'bio': 'textarea',
        'birth_date': 'date',
      },
      form: {},
      errors: {},
      processing: false
    }
  },
  computed: {
    ...mapGetters({ user: 'auth/user' }),
    premiumCubeImage() {
      return(
          this.user.is_premium && this.user.premium_settings &&
          this.user.premium_settings.cube_profile_image
      )
    }
  },
  components: {
    ProfileImageLazy,
    PremiumSettings,
    BuyMoreUniqueContainer,
    LinkButton,
    Spinner,
    Pencil,
    ImageIcon,
    UserFollowBox,
    Separator,
    Progress,
    Button,
    Activity,
    Input,
    Textarea
  },
  methods: {
    async submit() {
      this.processing = true
      const res = await this.form.submit()
      this.processing = false
      if(res.data.message) {
        if(this.form.success) {
          this.$toast.success(res.data.message)
          const user = this.$store.getters['auth/user']
          user.username = this.form.fields.username
          user.display_name = this.form.fields.display_name
          user.bio = this.form.fields.bio
          this.$store.commit('auth/setUserLoggedIn', user)
        }
        else this.$toast.error(res.data.message)
      }
      this.errors = this.form.errors
    }
  },
  beforeMount() {
    let fields: any = {}
    Object.keys(this.user).forEach((field: string) => {
      if(this.editable_field_names.includes(field)) {
        fields[field] = this.user[field]
      }
    })
    this.form = this.$wForm('/user/update', fields)
    this.form.put()
  }
})
export default class MePageView extends Vue {}
