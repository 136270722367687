
import { Options, Vue } from 'vue-class-component';
import Sheet from '@/components/utils/Sheet.vue'
//import Separator from '@/components/Separator.vue';
import ProfileInput from './ProfileInput.vue'

@Options({
     data() {
          return {
               
          }
     },
     components: {
          Sheet,
          //Separator,
          ProfileInput,
     },
     props: {
          showSheet: Boolean,
     },
})
export default class VibrationSheetComponent extends Vue {
     showSheet!: boolean;
}
