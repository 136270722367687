import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full mt-4" }
const _hoisted_2 = { class: "text-gray-500 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Box = _resolveComponent("Box")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('Search')), 1),
      _createVNode(_component_Input, { placeholder: "Start writing the language name" })
    ]),
    _createVNode(_component_Group, {
      title: _ctx.$t('Select')
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['en', 'hu'], (lang) => {
          return _createVNode(_component_Box, {
            key: lang,
            title: _ctx.$t(lang + '.fullname'),
            "use-slot": true,
            class: "group",
            onClick: ($event: any) => (_ctx.setLocale(lang)),
            disabled: _ctx.$i18n.locale === lang
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["transition duration-150 h-5 w-5 rounded-full", {'bg-gray-100 dark:bg-gray-700 group-hover:bg-gray-200 dark:group-hover:bg-gray-800': _ctx.$i18n.locale !== lang, '!bg-green-400': _ctx.$i18n.locale === lang, }])
              }, null, 2)
            ]),
            _: 2
          }, 1032, ["title", "onClick", "disabled"])
        }), 64))
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}