<template>
     <svg
     viewBox="0 0 256 256"
     xmlns="http://www.w3.org/2000/svg"
     class="svg-inline--fa fa-w-14"
     >
          <rect fill="none" />
          <path
               d="M210.3,35.9,23.9,88.4a8,8,0,0,0-1.2,15l85.6,40.5a7.8,7.8,0,0,1,3.8,3.8l40.5,85.6a8,8,0,0,0,15-1.2L220.1,45.7A7.9,7.9,0,0,0,210.3,35.9Z"
               fill="none"
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="20"
          />
          <line
               fill="none"
               stroke="currentColor"
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="20"
               x1="110.9"
               x2="156.1"
               y1="145.1"
               y2="99.9"
          />
     </svg>
</template>

<style scoped>
svg {
  transition: fill 0.5s;
  color: currentColor;
}

a[active="true"] svg path {
  fill: currentColor;
}
</style>