import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hashtags = _resolveComponent("Hashtags")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_More = _resolveComponent("More")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Hashtags),
    _createVNode(_component_Tag),
    _createVNode(_component_More)
  ], 64))
}