
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: String || null
  }
})

export default class MainLoader extends Vue {
  title !: string|null;
}
