import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "py-3 px-5 md:px-0 rounded-2xl bg-white dark:bg-gray-700 my-2 shadow max-w-sm" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-2 items-center" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-span-3" }
const _hoisted_6 = { class: "appFont text-xl truncate" }
const _hoisted_7 = { class: "text-gray-500 text-sm truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pause = _resolveComponent("Pause")!
  const _component_Play = _resolveComponent("Play")!
  const _component_BrowserLink = _resolveComponent("BrowserLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: _normalizeClass(["rounded-lg max-w-[55px]", {'animate-pulse': _ctx.specificPlayerPlaying(_ctx.getPlayerId())}]),
          src: _ctx.music.image_1x1,
          alt: _ctx.music.name
        }, null, 10, _hoisted_4),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.playOrPause && _ctx.playOrPause(...args))),
          class: "absolute cursor-pointer text-white"
        }, [
          (_ctx.specificPlayerPlaying(_ctx.getPlayerId()))
            ? (_openBlock(), _createBlock(_component_Pause, {
                key: 0,
                class: "fa-2x drop-shadow"
              }))
            : (_openBlock(), _createBlock(_component_Play, {
                key: 1,
                class: "fa-2x drop-shadow"
              }))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.music.name), 1),
        _createElementVNode("p", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.music.artists.length, (i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              _createVNode(_component_BrowserLink, {
                href: _ctx.music.artists[i-1].url
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.music.artists[i-1].name), 1)
                ]),
                _: 2
              }, 1032, ["href"]),
              (i-1 == _ctx.music.artists.length-2)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" & ")
                  ], 64))
                : (i-1 <= _ctx.music.artists.length-2)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(", ")
                    ], 64))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ])
    ])
  ]))
}