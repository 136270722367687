import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-gray-900 p-3 rounded-lg flex flex-wrap my-2 md:max-w-md" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-center text-xl pt-4 appFont py-3" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = {
  key: 0,
  class: "mb-3 flex flex-wrap"
}
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = { class: "break-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.max > _ctx.selected.length)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibility = true)),
          class: "rounded-lg"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Click here to select")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected, (sel) => {
        return (_openBlock(), _createElementBlock("button", {
          key: sel,
          onClick: ($event: any) => (_ctx.addOrRemove(sel)),
          class: "noselect transition-colors duration-75 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-600 px-3 py-2 m-1 rounded-lg max-w-100"
        }, [
          _createTextVNode(_toDisplayString(sel) + " ", 1),
          _createVNode(_component_CloseIcon, { class: "text-gray-500" })
        ], 8, _hoisted_2))
      }), 128))
    ]),
    _createVNode(_component_Sheet, {
      addHeader: true,
      visibility: _ctx.visibility,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visibility = false)),
      addPadX: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("h2", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('Select topics')) + " ( ", 1),
          _createElementVNode("span", {
            class: _normalizeClass({'text-red-400': _ctx.min > _ctx.selected.length})
          }, "Min: " + _toDisplayString(_ctx.min), 3),
          _createTextVNode(", "),
          _createElementVNode("span", {
            class: _normalizeClass({'text-red-400': _ctx.max <= _ctx.selected.length})
          }, "Max: " + _toDisplayString(_ctx.max), 3),
          _createTextVNode(" ) ")
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset, (content, group) => {
          return (_openBlock(), _createElementBlock("div", { key: group }, [
            _createElementVNode("h4", _hoisted_4, _toDisplayString(group.capitalize()), 1),
            (content.tags.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.tags, (tag) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: tag,
                      disabled: (!_ctx.selected.includes(tag) && _ctx.selected.length >= _ctx.max) || _ctx.processing,
                      onClick: ($event: any) => (_ctx.addOrRemove(tag)),
                      class: _normalizeClass(["noselect transition-colors duration-75 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-600 px-3 py-2 m-1 rounded-lg max-w-100", {'bg-primary-300 text-white dark:bg-primary-500 hover:bg-primary-200 dark:hover:bg-primary-400 focus:bg-primary-300 dark:focus:bg-primary-300': _ctx.selected.includes(tag)}])
                    }, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(tag.capitalize()), 1)
                    ], 10, _hoisted_6))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["visibility"])
  ], 64))
}