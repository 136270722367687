
import { Options, Vue } from 'vue-class-component';
import BackPage from '@/components/BackPage.vue'
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import ProfileImage from '@/components/me/ProfileImage.vue'

@Options({
  components: {
    BackPage,
    Layout,
    ProfileImage,
  },
})
export default class MeView extends Vue {}
