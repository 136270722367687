import axios from 'axios';
import store from '@/store';
import { tokenSet } from './tokenManager';

const refresh = async (jwt: any) => {
     let res
     try {
          res = await axios.get('/refresh', {
               headers: {
                    Authorization: `${jwt.type} ${jwt.token}`
               }
          });
     } catch(e: any) {
          store.commit('auth/logout');
     }
     if(res?.status == 200 && res?.data.authorization?.token && res?.data.authorization.type) {
          tokenSet(res.data.authorization.token, res.data.authorization.type)
          if(res.data.user) store.commit('auth/setUserLoggedIn', res.data.user);
     }
}

export default refresh;