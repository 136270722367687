import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Group = _resolveComponent("Group")!
  const _component_OurTeamSheet = _resolveComponent("OurTeamSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Group, {
      title: _ctx.$t('Help & Support')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Box, {
          icon: _ctx.icons.sheadset,
          title: _ctx.$t('Support'),
          onClick: _ctx.supportUrl
        }, null, 8, ["icon", "title", "onClick"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Group, {
      title: _ctx.$t('About us')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Box, {
          icon: _ctx.icons.team,
          title: _ctx.$t('Our team'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sheet.ot = true))
        }, null, 8, ["icon", "title"]),
        _createVNode(_component_Box, {
          icon: _ctx.icons.donation,
          title: _ctx.$t('Donate')
        }, null, 8, ["icon", "title"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_OurTeamSheet, {
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sheet.ot = false)),
      showSheet: _ctx.sheet.ot
    }, null, 8, ["showSheet"])
  ], 64))
}