export type State = {
     progress: number;
     working: boolean;
     fail: boolean;
}

const state: State = {
     progress: 0,
     working: false,
     fail: false,
}

const getters = {
     getProgress: (state: State): number => state.progress,
     isWorking: (state: State): boolean => state.working,
     isFailed: (state: State): boolean => state.fail
}

const actions = {
     async finished(context: any, fail = false) {
          context.state.fail = fail
          context.state.progress = 100
          await new Promise(resolve => setTimeout(resolve, 600))
          context.state.working = false
          context.state.progress = 0
     }
}

const mutations = {
     async setStatus(state: State, data: State) {
          state.fail = false
          if(!state.progress) {
               state.working = data.working
               await new Promise(resolve => setTimeout(resolve, 100))
               state.progress = data.progress
          }
     }
}

const namespaced = true;

export default {
     namespaced,
     state,
     getters,
     actions,
     mutations
}