
import { Options, Vue } from 'vue-class-component';
//import BackPage from '@/components/BackPage.vue'
import Layout from '@/components/layouts/AuthorizedLayout.vue'
//import LinkButton from '@/components/mobile/LinkButton.vue'
import PremiumIcon from '@/components/icons/Premium.vue'
import WhatIGetSheet from '@/components/premium/WhatIGetSheet.vue'
import PurchaseSheet from '@/components/premium/PurchaseSheet.vue'

@Options({
     data() {
          return {
               sheets: {
                    whatIGet: false,
                    purchase: false,
               }
          }
     },
     components: {
          //BackPage,
          Layout,
          //LinkButton,
          PremiumIcon,
          //Button,
          WhatIGetSheet,
          PurchaseSheet
     },
     beforeMount() {
          document.body.classList.add('app-bg-img')
     },
     beforeUnmount() {
          document.body.classList.remove('app-bg-img')
     }
})
export default class PremiumView extends Vue {}
