
import Box from "@/components/settings/Box.vue";
import Group from "@/components/settings/Group.vue";
import Switch from "@/components/utils/Switch.vue";
import {Options, Vue} from "vue-class-component";
import Input from '@/components/me/Input.vue'

@Options({
  components: {
    Box,
    Group,
    Switch,
    Input
  },
  methods: {
    setLocale(language: string) {
      this.$store.commit('settings/setLanguage', language)
      this.$i18n.locale = language
      this.axiosHE.put('/settings', {language})
      //window.location.reload()
    }
  }
})
export default class LanguagePage extends Vue {}
