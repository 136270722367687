
import { Options, Vue } from 'vue-class-component'
import Button from '@/components/auth/Button.vue'
import LogoutIcon from '@/components/icons/Logout.vue'
import SettingsIcon from '@/components/icons/Settings.vue'
import MagnifierIcon from '@/components/icons/Magnifier.vue'
import InAppLink from '@/components/utils/InAppLink.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import HashtagIcon from '@/components/icons/Hashtag.vue'
import NavInput from './assets/NavInput.vue'

@Options({
     data() {
          return {
               showNavbar: true,
               lastScrollPosition: 0,
               scrollValue: 0,
               search: {
                    open: false,
                    text: '',
               }
          }
     },
     components: {
          Button,
          LogoutIcon,
          SettingsIcon,
          MagnifierIcon,
          InAppLink,
          CloseIcon,
          HashtagIcon,
          NavInput
     },
     methods: {
          navHandler() {
               const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
               if (currentScrollPosition < 0) {
                    return
               }
               this.showNavbar = currentScrollPosition <= 80 || currentScrollPosition < this.lastScrollPosition    // Set the current scroll position as the last scroll position
               this.lastScrollPosition = currentScrollPosition
          },
          searchAction() {
               if(this.search.open) {
                    if(this.search.text.length < 3) return this.$toast.warning(this.$t('Please be more specific!'))
                    this.$router.push('/app/search?query=' + this.search.text)
               } else this.search.open = true
          }
     },
     mounted () {
          window.addEventListener('scroll', this.navHandler)
     },
     beforeDestroy () {
          window.removeEventListener('scroll', this.navHandler)
     }
})

export default class TopNavBar extends Vue {}
