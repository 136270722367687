import {App} from 'vue';
import {useToast} from "vue-toastification";
import axios from '@/ts/initAxios'
import axiosHE from '@/ts/axiosWithErrorHandler'
import tm from '@/ts/theme'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import hu from 'javascript-time-ago/locale/hu'
import profileImage from '../helpers/profileImage';
import aveta from 'aveta'
import wForm from '@/ts/wForm'
import store from "@/store";
import db from "@/ts/db";
import supportLink from "@/ts/supportLink";
import __dev_log from "@/ts/__dev_log";

TimeAgo.addLocale(hu)
TimeAgo.addDefaultLocale(en)

export default function addGlobals(app: App): void {
     // Global props
     app.config.globalProperties.axios = axios

     app.config.globalProperties.axiosHE = axiosHE

     app.config.globalProperties.$log = __dev_log

     app.config.globalProperties.$db = db

     app.config.globalProperties.$theme = tm

     app.config.globalProperties.$wForm = wForm

     app.config.globalProperties.$aveta = (num: number) => aveta(num, {
          precision: 1,
          lowercase: true,
     })

     app.config.globalProperties.user = () => {
          return store.getters['auth/user']
     }

     app.config.globalProperties.$cdn = (file: string) => {
          const images = [
               'https://images.pexels.com/photos/6753871/pexels-photo-6753871.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
               'https://1.bp.blogspot.com/-iCnFX7eWVjs/XR9NQutHXcI/AAAAAAAAJ9k/ISWH3UXgJF8QJdsV6P9wh3agzOwOF_aYgCLcBGAs/s1600/cat-1285634_1920.png#' + file,
               'https://images.pexels.com/photos/13746713/pexels-photo-13746713.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
               'https://images.pexels.com/photos/1183434/pexels-photo-1183434.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
               'https://images.pexels.com/photos/1629780/pexels-photo-1629780.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
          ];
          return images[Math.floor((Math.random()*images.length))];
     }

     app.config.globalProperties.$ta = () => {
          return new TimeAgo(store.getters['settings/getLanguage'] || 'en')
     }

     app.config.globalProperties.$toast = useToast()

     app.config.globalProperties.$profileImage = profileImage

     app.config.globalProperties.$supportLink = supportLink

     app.config.globalProperties.$sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
}