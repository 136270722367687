
import { Options, Vue } from 'vue-class-component';
import Layout from '@/components/layouts/AuthorizedLayout.vue'
import Page from '@/components/home/Page.vue'
import Posts from '@/components/home/Posts.vue'
import Post from "@/components/home/Post.vue";
import Answer from "@/components/home/Answer.vue";
import Spinner from "@/components/icons/Spinner.vue";

@Options({
  data() {
    return {
      data: {
        question:null,
        answers:null,
      },
      allLoaded: false,
      failedMore: false,
      usedMount: false,
      loadingMore: false,
    }
  },
  methods: {
    async load(cursor: string) {
      let res, error
      try {
        res = await this.axios.get(`/home/${this.$route.params.id}/?${cursor ? 'cursor='+cursor : ''}`)
      } catch (e: any) {
        console.error(e.message)
        error = true
      }
      if(error || res.status !== 200) return false
      else return res.data
    },
    async handleScroll() {
      if(!this.loadingMore) {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.pageYOffset;

        const isAtBottom = windowHeight + scrollPosition >= documentHeight-100;
        if (isAtBottom && this.data.answers && this.data.answers.next_cursor) {
          this.loadingMore = true
          await this.handleMore()
          await this.$sleep(1000)
          this.loadingMore = false
        }
      }
    },
    async handleMore() {
      const res = await this.load(this.data.answers.next_cursor)
      this.data.answers.next_cursor = res.answers.next_cursor
      this.data.answers.data.append(...res.data.answers.data)
    }
  },
  async beforeMount() {
    this.data = await this.load()
    document.body.classList.add('!bg-gray-50')
    document.body.classList.add('dark:!bg-gray-800')
  },
  beforeUnmount() {
    document.body.classList.remove('dark:!bg-gray-800')
    document.body.classList.remove('!bg-gray-50')
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  components: {
    Spinner,
    Answer,
    Post,
    Layout,
    Page,
    Posts
  },
})
export default class HomeView extends Vue {}
