
import {Options, Vue} from "vue-class-component";
import Separator from "@/components/Separator.vue";
import Lock from "@/components/icons/Lock.vue";

@Options({
  components: {Lock, Separator},
  methods: {
    hasMeta(link: any) {
      return link.meta && Object.keys(link.meta).length > 0
    },
    borderLeftColor(link: any) {
      if(this.hasMeta(link)) {
        this.$log.log(link.meta)
        if(Object.keys(link.meta).includes('theme-color')) return link.meta['theme-color']
      }
      return 'none';
    },
    url(uri: string){
      return new URL(uri)
    },
  },
  props: {
    link: Object
  }
})
export default class BioLinkMeta extends Vue {
  link!: object;
}
