
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/auth/Button.vue'
import StarIcon from '@/components/icons/Star.vue'
import UserProfile from "@/components/home/UserProfile.vue";

@Options({
     components: {
       UserProfile,
          Button,
          StarIcon
     },
     props: {
          q: Object||null,
     },
})
export default class HomePageQuestionView extends Vue {
     q!: object|null;
}
