import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-2xl premium-text-gradient mb-4 appFont" }
const _hoisted_2 = { class: "appFont text-xl" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-4 items-center justify-center mt-3" }
const _hoisted_4 = { class: "appFont text-xl mt-5" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { class: "titleFont" }
const _hoisted_7 = { class: "w-full grid grid-cols-2 gap-3 bg-white border-2 border-primary-200 p-3 rounded" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "titleFont mt-4" }
const _hoisted_11 = { class: "w-full grid grid-cols-2 gap-3 bg-gray-900 border-2 border-primary-200 p-3 rounded" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "appFont text-xl mt-5" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "mt-5" }
const _hoisted_17 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_Premium = _resolveComponent("Premium")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    class: "max-w-sm mx-auto"
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('Premium')), 1),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('Select the shape of your profile image')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cube_profile_image = false)),
        class: _normalizeClass([{'border-primary-200 border-2': !_ctx.cube_profile_image}, "transition duration-150 cursor-pointer w-20 h-20 rounded-full bg-gray-200 dark:bg-gray-800 mx-auto animate-pulse"])
      }, null, 2),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cube_profile_image = true)),
        class: _normalizeClass([{'border-primary-200 border-2': _ctx.cube_profile_image}, "transition duration-150 cursor-pointer w-20 h-20 rounded-md bg-gray-200 dark:bg-gray-800 mx-auto animate-pulse"])
      }, null, 2)
    ]),
    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('Username colors')), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t('Light mode')), 1),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors.light, (color, name) => {
          return (_openBlock(), _createBlock(_component_Tippy, {
            key: color,
            content: name.capitalize()
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                onClick: ($event: any) => (_ctx.username_colors.light = name),
                class: "h-10 w-full rounded cursor-pointer flex items-center justify-center text-white titleFont",
                style: _normalizeStyle({backgroundColor: color})
              }, [
                (_ctx.username_colors.light == name)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('Current')), 1))
                  : _createCommentVNode("", true)
              ], 12, _hoisted_8)
            ]),
            _: 2
          }, 1032, ["content"]))
        }), 128))
      ]),
      _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.$t('Dark mode')), 1),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors.dark, (color, name) => {
          return (_openBlock(), _createBlock(_component_Tippy, {
            key: color,
            content: name.capitalize()
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                onClick: ($event: any) => (_ctx.username_colors.dark = name),
                class: "h-10 w-full rounded cursor-pointer flex items-center justify-center text-black titleFont",
                style: _normalizeStyle({backgroundColor: color})
              }, [
                (_ctx.username_colors.dark == name)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('Current')), 1))
                  : _createCommentVNode("", true)
              ], 12, _hoisted_12)
            ]),
            _: 2
          }, 1032, ["content"]))
        }), 128))
      ]),
      _createVNode(_component_Separator),
      _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('Preview')), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["rounded border-2 border-primary-300 p-3 text-center", {'bg-white': _ctx.isLight, 'bg-gray-900': !_ctx.isLight}])
      }, [
        _createElementVNode("img", {
          src: _ctx.$profileImage(_ctx.user().profile_image_path),
          class: _normalizeClass(["w-20 h-20 rounded-full mx-auto border-2 mx-auto", {'!rounded-md': _ctx.cube_profile_image, 'border-gray-900': !_ctx.isLight}])
        }, null, 10, _hoisted_15),
        _createElementVNode("p", {
          class: "transition duration-150 appFont",
          style: _normalizeStyle({color: _ctx.isLight ? _ctx.colors.light[_ctx.username_colors.light] : _ctx.colors.dark[_ctx.username_colors.dark]})
        }, [
          _createTextVNode(" @" + _toDisplayString(_ctx.user().username) + " ", 1),
          _createVNode(_component_Tippy, { content: "Premium subscriber" }, {
            default: _withCtx(() => [
              _createVNode(_component_Premium, { class: "text-primary-300 cursor-pointer" })
            ]),
            _: 1
          })
        ], 4)
      ], 2),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLight = !_ctx.isLight)),
        class: "px-3 p-1 bg-gray-100 dark:bg-gray-800 rounded-lg mt-2 appFont"
      }, "Show in " + _toDisplayString(_ctx.isLight ? 'dark' : 'light') + " mode", 1)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_Button, {
        type: "submit",
        onClick: _ctx.update
      }, {
        default: _withCtx(() => [
          (!_ctx.processing)
            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('Save')), 1))
            : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("small", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('Note')) + ":", 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('Don\'t worry, your preferences will be kept permanently if your premium expires, and they will be applied when you subscribe again')) + " ;)", 1)
      ])
    ])
  ], 32))
}