import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-4 md:p-4 pb-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Box = _resolveComponent("Box")!
  const _component_Group = _resolveComponent("Group")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Team = _resolveComponent("Team")!
  const _component_BrowserLink = _resolveComponent("BrowserLink")!
  const _component_AccountPage = _resolveComponent("AccountPage")!
  const _component_SafetyPage = _resolveComponent("SafetyPage")!
  const _component_LanguagePage = _resolveComponent("LanguagePage")!
  const _component_NetworkPage = _resolveComponent("NetworkPage")!
  const _component_VibrationSheet = _resolveComponent("VibrationSheet")!
  const _component_FontsSheet = _resolveComponent("FontsSheet")!
  const _component_ProfileSettingsSheet = _resolveComponent("ProfileSettingsSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      (!_ctx.customView)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Group, {
              title: _ctx.$t('Account')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Box, {
                  title: _ctx.$t('Account'),
                  icon: _ctx.userIcon,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/app/settings/account')))
                }, null, 8, ["title", "icon"]),
                _createVNode(_component_Box, {
                  title: _ctx.$t('Safety'),
                  icon: _ctx.shieldIcon,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/app/settings/safety')))
                }, null, 8, ["title", "icon"]),
                _createVNode(_component_Box, {
                  title: _ctx.$t('Language'),
                  icon: _ctx.languageIcon,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/app/settings/language')))
                }, null, 8, ["title", "icon"]),
                _createVNode(_component_Box, {
                  title: _ctx.$t('Logout'),
                  onClick: _ctx.logout,
                  icon: _ctx.logoutIcon
                }, null, 8, ["title", "onClick", "icon"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Group, {
              title: _ctx.$t('Appearance')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Box, {
                  title: _ctx.$t('Theme'),
                  icon: _ctx.themeIcon,
                  useSlot: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Switch, {
                      checked: _ctx.isDark,
                      onChange: _ctx.switchTheme
                    }, null, 8, ["checked", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "icon"]),
                _createVNode(_component_Box, {
                  title: _ctx.$t('Blurry navigation bar'),
                  class: "lg:hidden",
                  icon: _ctx.blurIcon,
                  useSlot: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Switch, {
                      checked: _ctx.navStyle,
                      onChange: _ctx.switchNavTheme
                    }, null, 8, ["checked", "onChange"])
                  ]),
                  _: 1
                }, 8, ["title", "icon"]),
                _createVNode(_component_Box, {
                  title: _ctx.$t('Font'),
                  icon: _ctx.fontIcon,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sheets.fsheet = true))
                }, null, 8, ["title", "icon"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Group, {
              title: _ctx.$t('Network')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Box, {
                  title: _ctx.$t('Data traffic'),
                  icon: _ctx.wifiIcon,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/app/settings/network')))
                }, null, 8, ["title", "icon"])
              ]),
              _: 1
            }, 8, ["title"]),
            (_ctx.isApp)
              ? (_openBlock(), _createBlock(_component_Group, {
                  key: 0,
                  title: _ctx.$t('Mobile')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Box, {
                      title: _ctx.$t('Push notifications'),
                      icon: _ctx.pushNotificationIcon,
                      useSlot: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Switch, {
                          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$toast.warning(_ctx.$t('Not implemented'))))
                        })
                      ]),
                      _: 1
                    }, 8, ["title", "icon"]),
                    _createVNode(_component_Box, {
                      title: _ctx.$t('Vibration'),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.sheets.vsheet = true)),
                      icon: _ctx.phoneVibrationIcon
                    }, null, 8, ["title", "icon"])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Team),
            _createVNode(_component_Group, {
              title: _ctx.$t('Developers')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BrowserLink, {
                  class: "w-full",
                  href: "https://devs.wizzl.co"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Box, {
                      title: _ctx.$t('Public API'),
                      icon: _ctx.apiIcon
                    }, null, 8, ["title", "icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ], 64))
        : (_ctx.customView === 'account')
          ? (_openBlock(), _createBlock(_component_AccountPage, { key: 1 }))
          : (_ctx.customView === 'safety')
            ? (_openBlock(), _createBlock(_component_SafetyPage, { key: 2 }))
            : (_ctx.customView === 'language')
              ? (_openBlock(), _createBlock(_component_LanguagePage, { key: 3 }))
              : (_ctx.customView === 'network')
                ? (_openBlock(), _createBlock(_component_NetworkPage, { key: 4 }))
                : _createCommentVNode("", true)
    ]),
    _createVNode(_component_VibrationSheet, {
      onOnSync: _ctx.sync,
      showSheet: _ctx.sheets.vsheet,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sheets.vsheet = false))
    }, null, 8, ["onOnSync", "showSheet"]),
    _createVNode(_component_FontsSheet, {
      onOnSync: _ctx.sync,
      showSheet: _ctx.sheets.fsheet,
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.sheets.fsheet = false))
    }, null, 8, ["onOnSync", "showSheet"]),
    _createVNode(_component_ProfileSettingsSheet, {
      showSheet: _ctx.sheets.profile,
      onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.sheets.profile = false))
    }, null, 8, ["showSheet"])
  ], 64))
}