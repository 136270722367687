
import { Options, Vue } from 'vue-class-component'

@Options({
     props: {
          tippy: String||null,
     }
})
export default class HomeTopItemWrapComponent extends Vue {
     tippy!: string|null;
}
