
import { Options, Vue } from 'vue-class-component'
import Back from '@/components/icons/Back.vue'
import InAppLink from '@/components/utils/InAppLink.vue'
import isApp from '@/ts/mobile/isApp'

@Options({
     data() {
          return {
               isApp: isApp()
          }
     },
     components: {
          InAppLink,
          Back,
     },
     props: {
          to: String || null,
          sleep: Number||null,
     },
     methods: {
          async action() {
               if(this.sleep !== null) await this.$sleep(this.sleep)
               if(this.to) this.$router.push(this.to)
          }
     },
     mounted(){
          this.$log.log(this.isApp)
     }
})

export default class MobileLink extends Vue {
     to!: string|null;
     sleep!: number|null;
}
