import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Posts = _resolveComponent("Posts")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { removePadding: true }, {
    default: _withCtx(() => [
      _createVNode(_component_Posts, {
        posts: _ctx.data,
        onOnMore: _ctx.more,
        allLoaded: _ctx.allLoaded,
        failedMore: _ctx.failedMore
      }, null, 8, ["posts", "onOnMore", "allLoaded", "failedMore"])
    ]),
    _: 1
  }))
}