import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f00e0f5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-full mx-auto bg-gray-200 dark:bg-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.requestWorking)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["p-0.5 transition duration-150 customanim", {
                   'bg-red-300': (_ctx.requestProgress <= 35),
                   'bg-orange-300': (_ctx.requestProgress > 35 && _ctx.requestProgress <= 75),
                   'bg-green-300': (_ctx.requestProgress > 75),
                   '!bg-red-500': _ctx.requestFailed
                 }]),
          style: _normalizeStyle({'width': _ctx.requestProgress + '%'})
        }, null, 6)
      ]))
    : _createCommentVNode("", true)
}