
import { Options, Vue } from 'vue-class-component';
import isApp from '@/ts/mobile/isApp'

@Options({
     props: {
          classList: String||null,
          removePadding: Boolean||null,
     },
     data(){
          return {
               classes: {},
               app: isApp(),
          }
     },
     beforeMount(){
          if(this.classList !== null) this.classes = this.classList
     }
})
export default class AppLayout extends Vue {
     bgColor!: string|null;
     removePadding!: boolean|null;
}
