import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62e92782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-[90%] my-3 mx-auto flex flex-wrap text-left items-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-2xl pt-4 drop-shadow titleFont text-center w-full mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sheet = _resolveComponent("Sheet")!

  return (_openBlock(), _createBlock(_component_Sheet, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    class: "uploadGifSheetTheme",
    addHeader: true,
    visibility: _ctx.showSheet,
    addPadX: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: "h-[125px] w-full pImgCover rounded-t-xl",
        style: _normalizeStyle({
                    backgroundImage: `url(${require('@/assets/premium_cover.webp')})`
               })
      }, null, 4)
    ]),
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["visibility"]))
}