
import { Options, Vue } from 'vue-class-component'
import Spinner from '@/components/icons/Spinner.vue'

@Options({
     components: {
          Spinner
     },
     props: {
          hasIcon: Boolean,
          grayTheme: Boolean,
          isLoading: Boolean,
     }
})

export default class ButtonComponent extends Vue {
     hasIcon !: boolean;
     grayTheme!: boolean;
     isLoading!: boolean;
}
