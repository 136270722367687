
import { Options, Vue } from 'vue-class-component';
import Question from './Question.vue'
import Answer from './Answer.vue'
import LinkButton from '@/components/mobile/LinkButton.vue'
import { Data } from '@/views/Pages/fakedata/homeTypes'
import UpdatePost from '@/components/updates/UpdatePost.vue'
import Wrap from './topItems/Wrap.vue'
import Items from './topItems/items/Items.vue'
import Spinner from '@/components/icons/Spinner.vue'
import AnswerButton from './AnswerButton.vue'
import Error from '@/components/icons/Error.vue'
import PremiumBuy from './PremiumBuy.vue'

@Options({
     data() {
          return {
               loadingMore: false,
               styles: {}
          }
     },
     components: {
          Question,
          Answer,
          LinkButton,
          UpdatePost,
          Wrap,
          Items,
          Spinner,
          AnswerButton,
          Error,
          PremiumBuy
     },
     props: {
          questions: Array,
          singleQuestion: Boolean,
          failedMore: Boolean,
          allLoaded: Boolean,
     },
     methods: {
          async handleScroll() {
               if(!this.loadingMore) {
                    const windowHeight = window.innerHeight;
                    const documentHeight = document.documentElement.scrollHeight;
                    const scrollPosition = window.pageYOffset;

                    const isAtBottom = windowHeight + scrollPosition >= documentHeight-100;
                    if (isAtBottom) {
                         this.loadingMore = true
                         await this.$emit('onMore')
                         await this.$sleep(1000)
                         this.loadingMore = false
                    }
               }
          }
     },
     mounted() {
          window.addEventListener("scroll", this.handleScroll)
     },
     unmounted() {
          window.removeEventListener("scroll", this.handleScroll)
     }
})
export default class HomePageView extends Vue {
     questions!: Data;
     singleQuestion!: boolean;
     failedMore!: boolean;
     allLoaded!: boolean;
}
