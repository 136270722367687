
import { defineComponent } from 'vue'
import Spinner from "@/components/icons/Spinner.vue";

export default defineComponent({
  components: {Spinner},
     props: {
          questions: Number,
          answers: Number
     }
})
