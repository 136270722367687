import HomeView from '@/views/Pages/HomeView.vue'
import SettingsView from '@/views/Pages/SettingsView.vue'
import ChatsView from '@/views/Pages/ChatsView.vue'
import MeView from '@/views/Pages/Me.vue'
import QuestionView from '@/views/Pages/QuestionView.vue'
import UploadView from '@/views/Pages/UploadView.vue'
import GuardTypes from '../guard';
import SettingsCustomView from "@/views/Pages/SettingsCustomView.vue";
import MeEditView from "@/views/Pages/MeEditView.vue";
import MeEditBioLinksView from "@/views/Pages/MeEditBioLinksView.vue";
import User from "@/views/Users/User.vue";
import MeEditProfileImage from "@/views/Pages/MeEditProfileImage.vue";

const theme: NonNullable<unknown> = {
     statusbarColorIndex: 1,
     navbarColorIndex: 1
};

export default [
     {
          path: '/',
          redirect: '/app',
          meta: {
            auth: GuardTypes.ACCESS_ALL,
          },
     },
     {
          path: '/app',
          name: 'app',
          component: HomeView,
          meta: {
               hasNav: true,
               theme
          }
     },
     {
          path: '/app/upload',
          name: 'app.upload',
          component: UploadView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/app/settings',
          name: 'app.settings',
          component: SettingsView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/app/settings/:resource',
          name: 'app.settings.custom',
          component: SettingsCustomView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/app/question/:id',
          name: 'app.question',
          component: QuestionView,
          meta: {
               hasNav: true,
               theme
          }
     },
     {
          path: '/chat',
          name: 'chat.dm',
          component: ChatsView,
          meta: {
               hasNav: true,
          }
     },
     {
          path: '/me',
          name: 'app.me',
          component: MeView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/me/edit',
          name: 'app.me.edit',
          component: MeEditView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/me/bio-links',
          name: 'app.me.edit.resource',
          component: MeEditBioLinksView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/me/profile_image',
          name: 'app.me.edit.profile_image',
          component: MeEditProfileImage,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
     {
          path: '/user/:username',
          name: 'user.byUsername',
          component: User,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          }
     },
]