import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sm:text-lg appFont text-gray-700 dark:text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeartIcon = _resolveComponent("HeartIcon")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_ShareIcon = _resolveComponent("ShareIcon")!
  const _component_SaveIcon = _resolveComponent("SaveIcon")!
  const _component_ShareSheet = _resolveComponent("ShareSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center space-x-2 py-2 px-4 space-y-2 w-full sm:w-auto", {'md:px-2 md:space-x-0 md:grid md:grid-rows-3 md:grid-cols-1': _ctx.flexRow}])
    }, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('like'))),
        class: _normalizeClass(["transition duration-150 hover:text-red-400 mr-auto flex items-center space-x-3", {'text-red-400': _ctx.isLiked}])
      }, [
        _createVNode(_component_Tippy, { content: "Like" }, {
          default: _withCtx(() => [
            _createVNode(_component_HeartIcon, { class: "text-xl sm:text-3xl" })
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$aveta(_ctx.answer.likes_count)), 1)
      ], 2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.shareSheet = true)),
        class: "transition duration-150 hover:text-blue-300"
      }, [
        _createVNode(_component_Tippy, { content: "Share" }, {
          default: _withCtx(() => [
            _createVNode(_component_ShareIcon, {
              onClick: _ctx.share,
              class: "text-2xl sm:text-4xl"
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('save'))),
        class: _normalizeClass(["transition duration-150 hover:text-yellow-300", {'text-yellow-300': _ctx.isSaved}])
      }, [
        _createVNode(_component_Tippy, { content: "Save" }, {
          default: _withCtx(() => [
            _createVNode(_component_SaveIcon, { class: "text-2xl sm:text-4xl" })
          ]),
          _: 1
        })
      ], 2)
    ], 2),
    _createVNode(_component_ShareSheet, {
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.shareSheet = false)),
      visibility: _ctx.shareSheet
    }, null, 8, ["visibility"])
  ], 64))
}