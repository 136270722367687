
import { Options, Vue } from 'vue-class-component';
import MainLoader from '@/components/loaders/MainLoader.vue';
import ErrorPage from '@/components/ErrorPage.vue';
import { mapGetters } from 'vuex';
import setCurrentStatus from '@/ts/capacitor/networkHandler'
import HasNavLayout from '@/views/Layouts/HasNav.vue'
import addListeners from '@/ts/mobile/listeners'
import loadUp from '@/ts/app/loadUp';
import ProfileSetup from '@/components/app/ProfileSetup.vue'
import TransitionRouterView from "@/TransitionRouterView.vue";

@Options({
     data() {
          return {
               appLoaded: false
          }
     },
     computed: {
          ...mapGetters('loadScreen', ['isLoading', 'hasError', 'errorMessage', 'hasHelp', 'isServerError']),
          ...mapGetters('network', ['isActive']),
          ...mapGetters('profileSetupState', ['getDataset', 'isRequired']),
          ...mapGetters('settings', ['getLanguage'])
     },
     components: {
       TransitionRouterView,
          MainLoader,
          ErrorPage,
          HasNavLayout,
          ProfileSetup
     },
     async mounted(){
          addListeners()
          await setCurrentStatus();
          await loadUp()
          this.$i18n.locale = this.getLanguage
          this.appLoaded = true
     },
     methods: {
          hasNavbar() {
               if(this.$route.meta?.hasNav) return true;
               else return false;
          },
          themeSetter() {
              if(this.$route.meta?.theme) {
                  this.$theme.setShade(this.$route.meta.theme.statusbarColorIndex)
                  this.$theme.navbarColor(this.$route.meta.theme.navbarColorIndex)
                  this.$theme.save()
              }
          },
     },
     watch: {
          '$route' (to, from) {
               this.$log.log('Route changed from ' + from.path + ' to ' + to.path);
               this.themeSetter()
          }
     },
})
export default class App extends Vue {}
