import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isApp())
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        role: "button",
        to: _ctx.to,
        class: _normalizeClass(_ctx.classList)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
        class: _normalizeClass(_ctx.classList)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
}