import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-10 mb-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackPage = _resolveComponent("BackPage")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { disableTopNav: true }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BackPage, {
          title: _ctx.user.display_name,
          color2: true
        }, null, 8, ["title"]),
        _createVNode(_component_Page)
      ])
    ]),
    _: 1
  }))
}