
import { Options, Vue } from 'vue-class-component';
import SpinnerIcon from '@/components/icons/Spinner.vue'
import UserProfile from "@/components/home/UserProfile.vue";
import StarIcon from "@/components/icons/Star.vue";
import Button from "@/components/auth/Button.vue";
import UploadTypes from "@/components/upload/v2/UploadTypes.vue";
import Switch from "@/components/utils/Switch.vue";
import QuestionMark from "@/components/icons/QuestionMark.vue";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import LinkButton from "@/components/mobile/LinkButton.vue";
import MusicSelector from "@/components/upload/v2/MusicSelector.vue";
import MusicPlayer from "@/components/home/post/MusicPlayer.vue";
import {nextPostTime} from "@/ts/settings";
@Options({
    data() {
        return {
          is_anonymous: false,
          title: '',
          description: '',
          processing: false,
          files: {
            image: null,
            music: null,
            voice: null,
            gif: null
          },
          contentSelect: false,
          contentSelectContent: {},
          errors: {}
        }
    },
    methods: {
      resize(e: any, t = 1) {
        const el = e.target
        if(t == 1) el.style.height = "17px"
        else if(t == 2) el.style.height = "15px"
        el.style.height = el.scrollHeight + "px"
      },
      selectContent(content: string) {
        this.contentSelect = true
        this.contentSelectContent = content
      },
      musicSelected(song: any) {
        this.files.music = song
        this.contentSelect = false
      },
      async submit() {
        this.processing = true
        this.errors = {}
        let payload = {
          title: this.title,
          description: this.description,
          _content_type: 'none',
          asset: null,
        }
        if(this.hasUploaded) {
          if(this.files.music) {
            payload._content_type = 'music'
            payload.asset = this.files.music.id
          }
        }
        const res = await this.upload(payload)
        if(res.status == 200) {
          this.files = {}
          this.title = ''
          this.description = ''
          this.contentSelect = false
          this.contentSelectContent = {}
          if(res.data.message) this.$toast.success(res.data.message)
          await nextPostTime()
        } else {
          if(res.data?.errors) {
            this.errors = res.data.errors
          }
          if(res.data?.message) this.$toast.error(res.data.message)
          else this.$toast.error(this.$t('error.unknown'))
        }
        this.processing = false
      },
      async upload(payload: any) {
        let res
        try {
          res = await this.axios.post('/upload', payload)
        } catch(e: any) {
          this.$log.error(e.message)
          res = e.response
        }
        return res
      }
    },
    computed: {
      canSubmit() {
        return this.title.length >= 10
      },
      filesOk() {
        let nullCount = 0;
        for (let key in this.files) {
          if (this.files[key] !== null) nullCount++;
          if (nullCount > 1) return false;
        }
        return true;
      },
      hasUploaded() {
        let hasCount = 0;
        for (let key in this.files) {
          if (this.files[key] !== null) hasCount++;
        }
        return hasCount > 0;
      }
    },
     async beforeMount() {
          if(!this.$store.getters['postTimer/canUpload']) {
            this.$toast.error(this.$t('Hey! You have to wait'))
            return this.$router.push('/')
          }
          document.body.classList.add('app-bg-img')
     },
     beforeUnmount() {
          document.body.classList.remove('app-bg-img')
     },
     components: {
       MusicPlayer,
       MusicSelector,
       LinkButton,
       BrowserLink,
       QuestionMark,
       Switch,
       UploadTypes,
       Button,
       StarIcon,
       UserProfile,
       SpinnerIcon
    }
})
export default class UploadComponent extends Vue {}
