import PremiumView from '@/views/Pages/Premium.vue'

export default [
     {
          path: '/subscribe/premium',
          name: 'subscribe.premium',
          component: PremiumView,
          meta: {
               hasNav: true,
               theme: {
                    statusbarColorIndex: 0,
                    navbarColorIndex: 1
               }
          },
     },
]