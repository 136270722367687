import isApp from './isApp';
import { StatusBar } from '@capacitor/status-bar';

const setBackgroundColor = (color: string) => {
     if(isApp()) {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color });
     }
}

export {
     setBackgroundColor
}