import axios from 'axios'
import { API_ROUTES } from './apiRoutes';
import { tokenGet, tokenSet } from './auth/tokenManager';
import store from '@/store';
import isApp from "@/ts/mobile/isApp";
import {App} from "@capacitor/app";
import {Capacitor} from "@capacitor/core";
import supportLink from "@/ts/supportLink";

const instance = axios.create({
     baseURL: API_ROUTES.baseURL,
})

instance.interceptors.request.use(
     async (config) => {
          store.commit('requestLoader/setStatus', {
               progress: 45,
               working: true
          })
          const token = await tokenGet();
         config.headers['Accept'] = 'application/json';
          if(token && token.token) {
               config.headers['Authorization'] = token.token;
               config.headers['Accept-Language'] = store.getters['settings/getLanguage'] || navigator.language;
          }
          if(isApp()) {
              const info = await App.getInfo()
              config.headers['Native-App-Version'] = info.version
              config.headers['Native-App-Platform'] = Capacitor.getPlatform()
          }
          return config;
     },
     (error) => {
         return Promise.reject(error);
     }
);

instance.interceptors.response.use(
     async (response: any) => {
          store.dispatch('requestLoader/finished')
          if(response.status === 401) store.commit('auth/logout')
          if(response && response.data && response.data.authorization && response.data.authorization.token && response.data.authorization.type) {
               await tokenSet(response.data.authorization.token, response.data.authorization.type)
          }
          return response
     },
    (error: any) => {
        if(error.code == 'ERR_NETWORK') {
            store.commit('loadScreen/setScreen', {
                isLoading: false,
                hasError: true,
                errorMessage: 'Our servers could not be reached',
                server_error: true,
                help_url: supportLink('/error.Vue.initAxios.ERR_NETWORK')
            })
        }
        store.dispatch('requestLoader/finished', true)
        throw error
    }
);

export default instance