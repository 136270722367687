import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-gray-800 dark:text-gray-300" }
const _hoisted_2 = { class: "flex space-x-2 items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 mt-1 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock = _resolveComponent("Lock")!
  const _component_Tippy = _resolveComponent("Tippy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bg-gray-100 dark:bg-gray-800 mt-2 p-3 rounded-lg border-l-2",
    style: _normalizeStyle({borderColor: _ctx.borderLeftColor(_ctx.link)})
  }, [
    _createElementVNode("small", _hoisted_1, [
      (_ctx.url(_ctx.link.real_url).protocol == 'https:')
        ? (_openBlock(), _createBlock(_component_Tippy, {
            key: 0,
            content: "Secure protocol 😁"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Lock, { class: "cursor-pointer text-green-300" })
            ]),
            _: 1
          }))
        : (_ctx.url(_ctx.link.real_url).protocol == 'http:')
          ? (_openBlock(), _createBlock(_component_Tippy, {
              key: 1,
              content: "Insecure protocol 😟"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Lock, { class: "cursor-pointer text-red-300" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.url(_ctx.link.real_url).hostname), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.link.meta.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.link.meta.image,
            alt: "Site logo",
            class: "h-[50px] rounded noselect"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        (_ctx.link.meta.title)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.link.meta.title), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.link.meta.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.link.meta.description), 1))
      : _createCommentVNode("", true)
  ], 4))
}