
import { Options, Vue } from 'vue-class-component'
import ArrowRight from '@/components/icons/ArrowRight.vue'

@Options({
     components: {
         ArrowRight,
     },
     props: {
          title: String,
          desc: String||null,
          icon: Object,
          useSlot: Boolean||null,
     },
})

export default class MainLoader extends Vue {
     title!: string;
     desc!: string|null;
     icon!: object;
     useSlot!: boolean|null;
}
