
import { Options, Vue } from 'vue-class-component';
import Actions from './Actions.vue'
// import serverConfig from '@/ts/config/serverConfig'
import Button from '@/components/auth/Button.vue'
//import AOS from 'aos'
import { Clipboard } from '@capacitor/clipboard';
import UserProfile from "@/components/home/UserProfile.vue";

@Options({
     components: {
       UserProfile,
          Button,
          Actions
     },
     props: {
          a: Object||null,
     },
     methods: {
          async copy(data: string) {
               try {
                    await Clipboard.write({
                         string: data
                    });
                    this.$toast.success(this.$t('Successfully copied to clipboard'));
               } catch (error) {
                    this.$toast.error(this.$t('An unknown error occurred, maybe check your permissions'));
               }
          }
     },
     // mounted() {
     //      AOS.init()
     // }
})
export default class HomePageAnswerView extends Vue {
  a!: object|null
}
