import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { disabled: "" }
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opt) = $event)),
    class: "transition-colors duration-150 block w-full px-4 py-3 text-sm rounded-lg border-2 text-gray-600 bg-gray-100 border-gray-100 hover:bg-gray-200 hover:border-gray-200 focus:bg-gray-200 focus:border-gray-200 dark:text-gray-200 dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:bg-gray-700 dark:focus:border-gray-700"
  }, [
    _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.$t('Please select')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option,
        value: option,
        selected: _ctx.sel == option
      }, _toDisplayString(option.capitalize()), 9, _hoisted_2))
    }), 128))
  ], 512)), [
    [
      _vModelSelect,
      _ctx.opt,
      void 0,
      { lazy: true }
    ]
  ])
}