import store from "@/store";
import {Capacitor} from "@capacitor/core";

export default (path: string): string => {
    let user
    try {
        user = store.getters['auth/user']
    } catch(e: any) {
        console.error(e.message)
    }
    if(path.charAt(0) == '/') path = path.substring(1)
    const query_params: Record<string, string>  = {
        app_version: window.config.app.version,
        app_platform: Capacitor.getPlatform(),
        utm_source: Capacitor.getPlatform() == 'web' ? `${(new URL(window.location.href)).hostname}` : Capacitor.getPlatform(),
    }
    if(user) query_params.click_user_id = user.id
    return window.config.server.SUPPORT_LINK.replace(':q', path) + '?' + (new URLSearchParams(query_params).toString())
}