
import { Options, Vue } from 'vue-class-component'
import Hashtags from './Hashtags.vue'
import Tag from './Tag.vue'
import More from './More.vue'

@Options({
     components: {
          Hashtags,
          Tag,
          More
     }
})
export default class Items extends Vue {}
