import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg bg-gray-50 dark:bg-gray-800 p-3 px-5" }
const _hoisted_2 = { class: "md:mx-3" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "appFont text-3xl" }
const _hoisted_5 = { class: "premium-text-gradient" }
const _hoisted_6 = { class: "text-sm text-gray-500 my-5 appFont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          _createVNode(_component_PremiumIcon, { class: "text-primary-300" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('Support us via')) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('premium')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('subscription2')), 1)
        ])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('Relax, it\'s good for you too, there will be more and more new features for premium subscribers')), 1),
      _createVNode(_component_LinkButton, {
        to: "/subscribe/premium",
        class: "transition duration-150 !text-white mt-3 bg-gradient-to-r from-indigo-500 via-purple-400 to-pink-500 hover:opacity-80 focus:opacity-60 block appFont rounded-lg px-3 py-2 w-full md:w-1/2 mx-auto text-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('More information')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}