
import Input from '../Input.vue'
import {Options, Vue} from "vue-class-component";
import Button from "@/components/auth/Button.vue";
import Song from "@/components/icons/Song.vue";
import Play from "@/components/icons/Play.vue";
import Pause from "@/components/icons/Pause.vue";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import Dots from "@/components/icons/Dots.vue";
import MoreDots from "@/components/icons/MoreDots.vue";
import MusicPlayer from "@/components/home/post/MusicPlayer.vue";

@Options({
  data() {
    return {
      processing: false,
      search:'',
      songs: [],
      player: {
        progress: 0,
        song_id: null,
        player: null,
        paused: false,
      }
    }
  },
  methods: {
    async submit() {
      this.songs = []
      this.processing = true
      const res = await this.axiosHE.get('/spotify/search?q=' + encodeURI(this.search))
      if(res.status == 200 && res.data?.songs) this.songs = res.data.songs
      else {
        this.$toast.error(this.$t('error.unknown'))
        this.songs = []
      }
      this.processing = false
    },
  },
  components: {
    MusicPlayer,
    MoreDots,
    Dots,
    BrowserLink,
    Pause,
    Play,
    Song,
    Button,
    Input
  },
})
export default class extends Vue {}
