import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13ed1f1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hidden lg:block fixed top-0 left-0 w-full z-[999]" }
const _hoisted_2 = ["data-wizzl-bottom-bar"]
const _hoisted_3 = {
  key: 0,
  class: "w-full mx-auto border-b-1 rounded-lg border-gray-200 dark:border-gray-700"
}
const _hoisted_4 = { class: "grid grid-cols-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_RequestLoader = _resolveComponent("RequestLoader")!
  const _component_NavHomeIcon = _resolveComponent("NavHomeIcon")!
  const _component_NavLink = _resolveComponent("NavLink")!
  const _component_NavHeartIcon = _resolveComponent("NavHeartIcon")!
  const _component_NavClockIcon = _resolveComponent("NavClockIcon")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_NavMessagesIcon = _resolveComponent("NavMessagesIcon")!
  const _component_ProfileImageLazy = _resolveComponent("ProfileImageLazy")!
  const _component_MobileTypeHelper = _resolveComponent("MobileTypeHelper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SideBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RequestLoader)
    ]),
    (_ctx.isAuth && !_ctx.$store.getters['appearence/keyboardOpen'])
      ? (_openBlock(), _createElementBlock("footer", {
          key: 0,
          ref: "wizzlFooterNav",
          "data-wizzl-bottom-bar": _ctx.navStyle ? 'blurry' : 'default',
          class: _normalizeClass(["w-full noselect lg:hidden fixed bottom-0 z-10 text-gray-600 dark:text-white", {'bg-white dark:bg-gray-800':!_ctx.navStyle, 'bg-blur border-t border-t-primary-500': _ctx.navStyle && !_ctx.requestWorking}])
        }, [
          _createVNode(_component_RequestLoader),
          (!_ctx.navStyle)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_NavLink, {
              to: "/app",
              name: "Home",
              active: _ctx.isActive('/app')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NavHomeIcon)
              ]),
              _: 1
            }, 8, ["active"]),
            _createVNode(_component_NavLink, {
              to: "#",
              name: "Activity",
              active: _ctx.isActive('#')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NavHeartIcon)
              ]),
              _: 1
            }, 8, ["active"]),
            _createVNode(_component_NavLink, {
              onClick: _ctx.uploadTimer,
              disableIconColors: true,
              class: _normalizeClass({'text-red-400 shake-parent': !_ctx.canUpload, 'text-green-400 dark:text-green-300': _ctx.canUpload}),
              to: _ctx.canUpload ? '/app/upload' : '#',
              name: "Upload"
            }, {
              default: _withCtx(() => [
                (!_ctx.canUpload)
                  ? (_openBlock(), _createBlock(_component_NavClockIcon, {
                      key: 0,
                      class: "shake"
                    }))
                  : (_openBlock(), _createBlock(_component_Plus, { key: 1 }))
              ]),
              _: 1
            }, 8, ["onClick", "class", "to"]),
            _createVNode(_component_NavLink, {
              to: "#",
              name: "Chats",
              active: _ctx.isActive('#')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NavMessagesIcon)
              ]),
              _: 1
            }, 8, ["active"]),
            _createVNode(_component_NavLink, {
              to: "/me",
              name: "Me",
              active: _ctx.isActive('/me')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_ProfileImageLazy, {
                    user: _ctx.user,
                    "custom-class": "w-[34px] h-[34px] p-1 text-center"
                  }, null, 8, ["user"])
                ])
              ]),
              _: 1
            }, 8, ["active"])
          ])
        ], 10, _hoisted_2))
      : (_openBlock(), _createBlock(_component_MobileTypeHelper, { key: 1 }))
  ], 64))
}