
import { Options, Vue } from 'vue-class-component';
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import Form from '@/components/auth/Form.vue'
import InAppLink from '@/components/utils/InAppLink.vue'

// loadup
import loadUp from '@/ts/app/loadUp'

@Options({
  data(){
    return {
      fields: [
        {
          placeholder: this.$t('Username or email'),
          icon: 'user',
          type: 'text',
          name: 'username',
          title: this.$t('Enter your username or email') + ' 😎',
        },
        {
          placeholder: this.$t('Password'),
          icon: 'lock',
          type: 'password',
          name: 'password',
          title: this.$t('We\'re almost done') + ' 😉',
        }
      ]
    }
  },
  components: {
    AuthLayout,
    Form,
    InAppLink
  },
  methods: {
    async authUser(data: any) {
      if(data.user) {
        this.$store.commit('auth/setUserLoggedIn', data.user);
        await loadUp()
        this.$router.push('/');
      } else this.$toast.error(this.$t('error.unknown'))
    }
  }
})
export default class SignInView extends Vue {}
