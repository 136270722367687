
import { Options, Vue } from 'vue-class-component'
import ItemWrap from '../ItemWrap.vue'
import TagIcon from '@/components/icons/Tag.vue'

@Options({
     components: {
          ItemWrap,
          TagIcon
     }
})
export default class Terms extends Vue {}
