import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "transition-transform duration-500 mx-auto !w-12 !h-12 rounded-full bg-gray-300 dark:bg-gray-800 animate-pulse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileImageLazy = _resolveComponent("ProfileImageLazy")!
  const _component_Tippy = _resolveComponent("Tippy")!

  return (_ctx.user || _ctx.is_anonymous)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProfileImageLazy, {
          "premium-cube": _ctx.premiumCubeImage,
          "custom-class": "!w-12 !h-12 !mr-4",
          user: _ctx.user,
          "raw-image": _ctx.is_anonymous ? require('@/assets/incognito.jpg') : null
        }, null, 8, ["premium-cube", "user", "raw-image"]),
        (_ctx.show_is_online && (!_ctx.is_anonymous && _ctx.user && _ctx.user.is_online))
          ? (_openBlock(), _createBlock(_component_Tippy, {
              key: 0,
              content: "Currently online",
              class: _normalizeClass(["h-3 w-3 bg-green-400 rounded-full absolute cursor-pointer", {'ml-8 -mt-3': !_ctx.premiumCubeImage,'ml-10 -mt-2': _ctx.premiumCubeImage}])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2))
}