import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_AnswerSheet = _resolveComponent("AnswerSheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Tippy, { content: "Answer" }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadAnswerModal && _ctx.loadAnswerModal(...args))),
          class: "mb-2 block w-full border-2 border-dashed transition duration-150 rounded-lg text-gray-400 border-gray-200 hover:border-gray-300 focus:bg-gray-200 dark:border-gray-950 dark:hover:border-gray-900 dark:focus:bg-gray-900 dark:text-gray-950 p-3 text-center cursor-pointer appFont"
        }, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "+"))
            : (_openBlock(), _createBlock(_component_Spinner, {
                key: 1,
                class: "text-black dark:text-white"
              }))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AnswerSheet, {
      "show-sheet": _ctx.showSheet,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSheet = false))
    }, null, 8, ["show-sheet"])
  ], 64))
}