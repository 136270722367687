
import { Options, Vue } from 'vue-class-component';
import Common from './Common.vue'
import Separator from '@/components/Separator.vue';
import Button from '@/components/auth/Button.vue'
import SpinnerIcon from '@/components/icons/Spinner.vue'
import MoneyShieldIcon from '@/components/icons/MoneyShield.vue'

@Options({
     methods: {
          select(i: number) {
               if(this.selected == i) this.selected = false
               else this.selected = i
          }
     },
     data() {
          return {
               subscriptions: [
                    {
                         price: 2.5,
                         period: this.$t('Monthly'),
                         purchase_id: 'purchase_id',
                         most_popular: true
                    },
                    {
                         price: 6.5,
                         period: this.$t('Every 3 months'),
                         purchase_id: 'purchase_id',
                         most_popular: false,
                    },
                    {
                         price: 22.5,
                         period: this.$t('Annually'),
                         purchase_id: 'purchase_id',
                         most_popular: false,
                    },
               ],
               selected: false
          }
     },
     props: {
          showSheet: Boolean,
     },
     components: {
          Common,
          Separator,
          Button,
          SpinnerIcon,
          MoneyShieldIcon
     },
})
export default class GifSheetComponent extends Vue {
     showSheet!: boolean;
}
