
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/auth/Button.vue'
import PremiumIcon from '@/components/icons/Premium.vue'
import LinkButton from '@/components/mobile/LinkButton.vue'

@Options({
     components: {
          Button,
          PremiumIcon,
          LinkButton
     },
})
export default class HomePageQuestionView extends Vue {}
