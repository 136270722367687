import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-500f5742"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper my-4 py-5 pb-20" }
const _hoisted_2 = { class: "px-3 rounded my-2 lg:rounded-2xl lg:shadow-xs drop-shadow-sm" }
const _hoisted_3 = { class: "flex items-center rounded-lg" }
const _hoisted_4 = { class: "m-auto" }
const _hoisted_5 = { class: "text-center appFont mt-2 text-xl" }
const _hoisted_6 = { class: "mt-10 grid grid-cols-3 grid-rows-1" }
const _hoisted_7 = { class: "my-3" }
const _hoisted_8 = { class: "my-3" }
const _hoisted_9 = { class: "appFont text-xl mb-3" }
const _hoisted_10 = { class: "my-3" }
const _hoisted_11 = { class: "appFont text-xl mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileImageLazy = _resolveComponent("ProfileImageLazy")!
  const _component_Pencil = _resolveComponent("Pencil")!
  const _component_InAppLink = _resolveComponent("InAppLink")!
  const _component_UserFollowBox = _resolveComponent("UserFollowBox")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Activity = _resolveComponent("Activity")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ProfileImageLazy, {
            "premium-cube": _ctx.premiumCubeImage,
            user: _ctx.user
          }, null, 8, ["premium-cube", "user"]),
          _createElementVNode("h2", _hoisted_5, [
            _createTextVNode(" @" + _toDisplayString(_ctx.user.username) + " ", 1),
            _createVNode(_component_InAppLink, { to: "/me/edit" }, {
              default: _withCtx(() => [
                _createVNode(_component_Pencil, { class: "transition duration-150 cursor-pointer hover:text-primary-300" })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_6, [
        _createVNode(_component_UserFollowBox, {
          title: "Followers",
          count: _ctx.user.followers_count
        }, null, 8, ["count"]),
        _createVNode(_component_UserFollowBox, {
          title: "Following",
          count: _ctx.user.followings_count
        }, null, 8, ["count"]),
        _createVNode(_component_UserFollowBox, {
          title: "Points",
          count: "150"
        })
      ]),
      _createVNode(_component_Separator),
      _createElementVNode("section", _hoisted_7, [
        _createVNode(_component_LinkButton, {
          class: "w-full",
          to: "/me/edit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "appFont",
              grayTheme: true
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Profile Settings')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("section", _hoisted_8, [
        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('Progress')), 1),
        _createVNode(_component_Progress)
      ]),
      _createElementVNode("section", _hoisted_10, [
        _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('Activity')), 1),
        _createVNode(_component_Activity, {
          questions: _ctx.user.questions_count,
          answers: _ctx.user.answers_count
        }, null, 8, ["questions", "answers"])
      ])
    ])
  ]))
}