
import {Options, Vue} from 'vue-class-component'
import Group from './Group.vue'
import Box from './Box.vue'
import Switch from '@/components/utils/Switch.vue'
import ThemeIcon from '@/components/icons/Theme.vue'
import FontIcon from '@/components/icons/Font.vue'
import UserIcon from '@/components/icons/User.vue'
import ShieldIcon from '@/components/icons/Shield.vue'
import LogoutIcon from '@/components/icons/Logout.vue'
import BlurIcon from '@/components/icons/Blur.vue'
import PushNotificationIcon from '@/components/icons/PushNotification.vue'
import PhoneVibrationIcon from '@/components/icons/PhoneVibration.vue'
import ApiIcon from '@/components/icons/Api.vue'
import EarthLanguageIcon from "@/components/icons/EarthLanguage.vue";
import WifiIcon from "@/components/icons/Wifi.vue";
// Pages
import Team from './Team.vue'
// Store
import isApp from '@/ts/mobile/isApp'
// sheets
import VibrationSheet from './sheets/VibrationSheet.vue'
import FontsSheet from './sheets/FontsSheet.vue'
import ProfileSettingsSheet from './sheets/ProfileSettingsSheet.vue'
import {AxiosError} from 'axios'
import AccountPage from "@/components/settings/pages/AccountPage.vue";
import SafetyPage from "@/components/settings/pages/SafetyPage.vue";
import BrowserLink from "@/components/utils/BrowserLink.vue";
import LanguagePage from "@/components/settings/pages/LanguagePage.vue";
import NetworkPage from "@/components/settings/pages/NetworkPage.vue";

@Options({
  data() {
    return {
      isApp: isApp(),
      sheets: {
        vsheet: false,
        fsheet: false,
        profile: false,
      },
      isDark: false,
      navStyle: false,
      themeIcon: <ThemeIcon/>,
      fontIcon: <FontIcon/>,
      userIcon: <UserIcon/>,
      shieldIcon: <ShieldIcon/>,
      logoutIcon: <LogoutIcon/>,
      blurIcon: <BlurIcon/>,
      pushNotificationIcon: <PushNotificationIcon/>,
      phoneVibrationIcon: <PhoneVibrationIcon/>,
      apiIcon: <ApiIcon/>,
      languageIcon: <EarthLanguageIcon/>,
      wifiIcon: <WifiIcon/>,
    }
  },
  components: {
    NetworkPage,
    LanguagePage,
    BrowserLink,
    SafetyPage,
    AccountPage,
    Group,
    Box,
    Switch,
    VibrationSheet,
    FontsSheet,
    ProfileSettingsSheet,
    Team
  },
  methods: {
    switchTheme() {
      this.isDark = !this.isDark
      this.$theme.setTheme(this.isDark ? 'dark' : 'light')
      this.sync({
        dark_mode: this.isDark
      })
    },
    async logout() {
      try {
        await this.axios.post('/logout')
      } catch (e: AxiosError | any) {
        this.$toast.error(e.message || this.$t('error.unknown'))
        return
      }
      this.$store.commit('auth/logout')
      window.localStorage.removeItem('token')
      this.$router.push('/auth')
    },
    async switchNavTheme() {
      this.navStyle = await this.$db.get('settings.bottom-nav-style')
      if (!this.navStyle) {
        this.navStyle = true
      } else {
        this.navStyle = false
      }
      await this.$db.set('settings.bottom-nav-style', this.navStyle)
      this.$store.commit('settings/setNavbarBlurry', this.navStyle)
      await this.sync({
        blurry_navbar: this.navStyle
      })
    },
    async sync(data = {}) {
      let res
      try {
        res = await this.axios.put('/settings', data)
      } catch (e: any) {
        this.$toast.error(`[${res.response.status}] Failed to update the settings in the server ://`)
      }
      if (res?.status == 200) this.$log.log('Updated')
      this.syncReqDatas = []
    },
  },
  async beforeMount() {
    this.navStyle = await this.$db.get('settings.bottom-nav-style')
    this.isDark = this.$theme.getTheme() === 'dark'
  },
  props: {
    customView: String || null
  }
})

export default class MainLoader extends Vue {
  customView!: string | null;
}
